import { Component, OnInit, inject, input, output } from '@angular/core';
import { Facility } from 'src/app/model/locations/facility';
import { DeviceService } from 'src/app/services/device.service';
import { isNullOrEmptyArray } from 'src/app/core/functions/common-functions';

@Component({
    selector: 'app-contact-associate-facilities',
    templateUrl: './contact-associate-facilities.component.html',
    styleUrls: ['./contact-associate-facilities.component.scss'],
    standalone: false
})
export class ContactAssociateFacilitiesComponent implements OnInit {
  private _deviceService = inject(DeviceService);

  readonly facilities = input<Facility[]>(undefined);
  readonly openFacilityEvent = output<any>();
  readonly openContactListEvent = output<any>();

  ngOnInit() {}

  isMobile(): boolean {
    return this._deviceService.isMobile();
  }

  isFacilitiesEmpty(): boolean {
    return isNullOrEmptyArray(this.facilities());
  }
}
