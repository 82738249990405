import {
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  viewChild,
  inject,
  input,
  output,
  Output,
  EventEmitter,
  signal,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LazyLoadEvent } from 'primeng/api';
import { take } from 'rxjs/operators';
import {
  CONTACT_LIST_DETAIL_CONTAINER,
  CREATE_CONTACT_LIST_CONTAINER,
} from 'src/app/constants/common.constants';
import { SearchContainerComponent } from 'src/app/core/containers/search-container/search-container.component';
import { DeviceSize } from 'src/app/core/enums/deviceSize.enum';
import { TabService } from 'src/app/core/services/tab.service';
import { ContactList } from 'src/app/model/contacts/contact-list';
import { AuthService } from 'src/app/services/auth.service';
import { ContactListApiService } from 'src/app/services/contact-list-api.service';
import { ContactListBuilderService } from 'src/app/services/contact-list-builder.service';
import { ContactListTableService } from 'src/app/services/contact-list-table.service';
import { AuthApiService } from 'src/app/services/auth-api.service';
import { DeviceService } from 'src/app/services/device.service';
import { LoadingService } from 'src/app/services/loading.service';
import { getContactListColumns } from '../../definitions/contact-lists-table-definition';
import { BreadCrumbBuilderService } from 'src/app/services/breadcrumb-builder.service';
import { BreadCrumb } from 'src/app/model/common/bread-crumb';
import { Subscription } from 'rxjs';
import { BusinessUnitFilterComponent } from 'src/app/core/components/business-unit-filter/business-unit-filter.component';
import { isNullOrUndefined } from 'src/app/utils/utils';
import { CLAIMS } from 'src/app/constants/auth-constants';

@Component({
  selector: 'app-contact-list-search-container',
  templateUrl: './contact-list-search-container.component.html',
  styleUrls: ['./contact-list-search-container.component.scss'],
  standalone: false,
})
export class ContactListSearchContainerComponent
  extends SearchContainerComponent<ContactList>
  implements OnInit, OnDestroy
{
  private _translateService = inject(TranslateService);
  private _breadCrumbBuilderService = inject(BreadCrumbBuilderService);
  protected _deviceService: DeviceService;
  private _contactListApi = inject(ContactListApiService);
  private _contactListBuilder = inject(ContactListBuilderService);
  private _contactListTableService = inject(ContactListTableService);
  private _authApi = inject(AuthApiService);
  protected _cdRef: ChangeDetectorRef;
  protected _auth: AuthService;
  protected _loader: LoadingService;

  readonly embedded = input(false);
  readonly businessUnitFilter =
    viewChild<BusinessUnitFilterComponent>('buFilter');
  readonly selectedListEvent = output<any>();

  allContactLists: any[];
  loading: boolean;
  contactsLoading: boolean;
  availableCompanies: any[];
  totalRecords: number;
  @Output() contactsLoadingEvent = new EventEmitter<any>();
  canCreate = signal(false);
  scrollHeight = 'calc(100vh - 256px)';
  mobileFilterCollapsed = true;
  activeTabChangedSub: Subscription;

  constructor() {
    const _deviceService = inject(DeviceService);
    const _cdRef = inject(ChangeDetectorRef);
    const _auth = inject(AuthService);
    const _loader = inject(LoadingService);

    super(_deviceService, _loader, _auth, _cdRef);
    this._deviceService = _deviceService;
    this._cdRef = _cdRef;
    this._auth = _auth;
    this._loader = _loader;
  }

  ngOnInit() {
    this.setupActiveTabChangedSubscription();
    this.setTableService(this._contactListTableService);
    if (this._loader.isLoaded()) {
      this.initialize();
    } else {
      this._loader.loadingFinishedEvent.subscribe((event) => {
        this.initialize();
      });
    }
  }

  initialize() {
    this.canCreate.set(this._authApi.doesUserHaveClaim(CLAIMS.CONTACTS.CONTACT_LISTS.CREATE_CONTACT_LISTS));
    if (this.embedded() === false) {
      // setup the bread crumb.
      this._translateService
        .get('CONTACT.SCREEN.CONTACT_LIST')
        .subscribe((label) => {
          if (this._deviceService.isMobile()) {
            this._breadCrumbBuilderService.resetAndAddBreadCrumb(
              new BreadCrumb(label, null, false)
            );
          } else {
            TabService.getInstance().updateActiveTabLabel(label);
          }
          this.screenName = label;
        });
    }

    if (this.ifDeviceMatches([DeviceSize.XS, DeviceSize.SM])) {
      this.scrollHeight = 'calc(100vh - 180px)';
    }

    const options = [
      { label: 'Choose', value: null },
      { label: 'True', value: true },
      { label: 'False', value: false },
    ];

    this.columns = getContactListColumns(options);
    this.applyPreviousData();
    this.applyPreviousFilters();
  }

  ngOnDestroy(): void {
    if (this.activeTabChangedSub) {
      this.activeTabChangedSub.unsubscribe();
    }
  }

  /**
   * Method called when the user hits the clear button.
   */
  clearScreen() {
    this.lazyLoad({ lazy: {} });
    this.grid().resetTable();
    this._contactListTableService.clearResults();
    this.elements = [];
  }

  /**
   * Method called when a row double click event is fired.  This navigates to the details page.
   * @param $event - Event from the double click event.
   */
  changeViewEvent($event) {
    if (this.embedded() === false) {
      this._contactListTableService.setSelected(null); // make sure we grab by id after it opens
      const tab = TabService.getInstance().buildNewTab(
        CONTACT_LIST_DETAIL_CONTAINER,
        true,
        null,
        $event.data.id
      );
      if (this._deviceService.isMobile()) {
        TabService.getInstance().setMobileTab(tab);
      } else {
        TabService.getInstance().openTab(tab);
      }
    } else {
      this.selectedListEvent.emit($event);
    }
  }

  newContactList() {
    const tab = TabService.getInstance().buildNewTab(
      CREATE_CONTACT_LIST_CONTAINER,
      true
    );
    if (this._deviceService.isMobile()) {
      TabService.getInstance().setMobileTab(tab);
    } else {
      TabService.getInstance().openTab(tab);
    }
  }

  queryNetwork(req, $event, query) {
    req.page += 1;
    const sort = {};
    if ($event.lazy && $event.lazy.sortField) {
      sort[$event.lazy.sortField] =
        $event.lazy.sortOrder && $event.lazy.sortOrder === 1 ? 'DESC' : 'ASC';
      this._contactListTableService.setLastSortField($event.lazy.sortField);
      this._contactListTableService.setLastSortDirection($event.lazy.sortOrder);
    } else {
      sort['name'] = 'ASC';
      this._contactListTableService.setLastSortField('name');
      this._contactListTableService.setLastSortDirection(1);
    }

    this._contactListApi
      .queryForContactLists(req.pageSize, req.page, query, sort)
      .pipe(take(1))
      .subscribe(
        ({ data }) => {
          const clone = Object.assign({}, data);
          this.totalRecords = clone.queryForContactLists.totalRecords;
          this._contactListTableService.setLastTotalRecords(this.totalRecords);
          this.allContactLists = [
            ...clone.queryForContactLists.items.map((i) =>
              this._contactListBuilder.buildContactListSearch(i)
            ),
          ];
          this.elements = [...this.allContactLists];
          this.loading = false;
          this._cdRef.markForCheck();
        },
        (error) => {
          console.log(error);
          this.totalRecords = 0;
          this.allContactLists = [];
          this.elements = [];
          this.loading = false;
        }
      );
  }

  handleLazyLoad(req, $event, filters) {
    let selectedBusinessUnits = [];
    const businessUnitFilter = this.businessUnitFilter();
    if (businessUnitFilter?.selectedBusinessUnits) {
      // Handle further refreshes & changes to business unit filter component
      selectedBusinessUnits = businessUnitFilter.selectedBusinessUnits;
    } else {
      // Handle initial page load (before the business unit filter subcomponent exists)
      selectedBusinessUnits = this._authApi
        .getUserBusinessUnits()
        .map((bu) => bu.id);
    }
    filters['businessUnits'] = selectedBusinessUnits;

    const query = this._contactListBuilder.buildContactListQuery(filters);
    this._contactListTableService.setLastQuery(query);
    this.queryNetwork(req, $event, query);
  }

  mobileFilter($event) {
    const newLazy: LazyLoadEvent = {
      filters: {
        ...this.grid().table().filters,
        state: $event.state
          ? {
              value: $event.state,
              matchMode: 'contains',
            }
          : null,
        county: $event.county
          ? {
              value: $event.county,
              matchMode: 'contains',
            }
          : null,
        township: $event.township
          ? {
              value: $event.township,
              matchMode: 'contains',
            }
          : null,
      },
    };
    this.mobileFilterCollapsed = true;
    this._contactListTableService.setLastLazyLoad(newLazy);
    this.lazyLoad({ lazy: this._contactListTableService.getLastLazyLoad() });
  }

  refresh(): void {
    this.lazyLoad({ lazy: this._contactListTableService.getLastLazyLoad() });
  }

  setupActiveTabChangedSubscription(): void {
    this.activeTabChangedSub =
      TabService.getInstance().activeTabChanged.subscribe((tab) => {
        if (!isNullOrUndefined(tab) && tab.header === this.screenName) {
          this.allContactLists = [];
          this.refresh();
        }
      });
  }

  handleBusinessUnitChange($event) {
    this.lazyLoad({ lazy: this._contactListTableService.getLastLazyLoad() });
  }
}
