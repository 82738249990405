import { Injectable, inject } from '@angular/core';
import { ContactList } from '../model/contacts/contact-list';
import { DirtyStatus } from '../model/common/dirty-status';
import { v4 as uuid } from 'uuid';
import { Contact } from '../model/contacts/contact';
import { ContactListMember } from '../model/contacts/contact-list-member';
import { FacilityBuilderService } from './facility-builder.service';
import { onlyUnique } from '../core/functions/common-functions';
import { ContactBuilderService } from './contact-builder.service';
import { ExternalContactList } from '../model/contacts/external-contact-list';
import { isNullOrUndefined } from 'util';
import { ContactListUpdater } from '../model/contacts/contact-list-updater';
import { ContactListSearch } from '../model/contacts/contact-list-search-results';
import { Schedule } from '../model/contacts/contact-list-scheduler';
import { utc, tz } from 'moment';
import { getCorrectedDate } from '../utils/utils';
import { ConfigItem } from '../model/common/config-item';

@Injectable({
  providedIn: 'root',
})
export class ContactListBuilderService {
  private _facilityBuilder = inject(FacilityBuilderService);
  private _contactBuilder = inject(ContactBuilderService);


  buildContactListQuery(filters: any) {
    const query = {
      state: null,
      county: null,
      township: null,
      name: null,
      code: null,
      businessUnits: null,
    };

    if (filters.state) {
      query.state = filters.state.value;
    }
    if (filters.county) {
      query.county = filters.county.value;
    }
    if (filters.township) {
      query.township = filters.township.value;
    }
    if (filters.name) {
      query.name = filters.name.value;
    }
    if (filters.code) {
      query.code = filters.code.value;
    }
    if (filters.businessUnits) {
      query.businessUnits = filters.businessUnits;
    }

    return query;
  }

  buildExternalContactListQuery(filters: any) {
    const query = {
      name: null,
      businessUnits: null,
    };

    if (filters.name) {
      query.name = filters.name.value;
    }
    if (filters.businessUnits) {
      query.businessUnits = filters.businessUnits;
    }

    return query;
  }

  buildContactListSearch(item: any): ContactListSearch {
    return {
      id: item.id,
      name: item.name,
      code: item.memberCodes,
      state: item.state,
      county: item.county,
      township: item.township,
    };
  }

  buildContactList(item: any): ContactList {
    if (item === null) {
      return null;
    }

    const retVal = {
      id: item.id,
      code: item.MemberCodes
        ? item.MemberCodes.map((mc) => mc.code)
            .filter(onlyUnique)
            .join(', ')
        : '',
      memberCodes: item.MemberCodes
        ? item.MemberCodes.map((mc) => ({
            id: mc.id,
            code: mc.code,
          }))
        : [],
      origMemberCodes: item.MemberCodes ? item.MemberCodes : [],
      name: item.name,
      members: item.ContactListMembers
        ? item.ContactListMembers.map((clm) => this.buildContactListMember(clm))
        : [],
      updaters: item.ContactListUpdaters
        ? item.ContactListUpdaters.map((clm) => ({
            id: clm.id,
            contactId: clm.ContactId,
            contactListId: item.id,
          }))
        : [],
      facilities: item.Facilities
        ? item.Facilities.map((f) => {
            return this._facilityBuilder.buildFacility(f, []);
          })
        : [],
      createdAt: item.createdAt,
      updatedAt: item.updatedAt,
      deletedAt: item.deletedAt,
      businessUnits: item.BusinessUnits,
    };
    retVal['state'] = retVal.facilities
      .map((f) => f.state)
      .filter(onlyUnique)
      .join(', ');
    retVal['county'] = retVal.facilities
      .map((f) => f.county)
      .filter(onlyUnique)
      .join(', ');
    retVal['township'] = retVal.facilities
      .map((f) => f.locations.map((l) => l.township))
      .reduce((acc, val) => acc.concat(val), []) // .flat isn't supported in IE/EDGE
      .filter(onlyUnique)
      .join(', ');
    return retVal;
  }

  buildContactListForDropdown(item: any): ConfigItem {
    return {
      id: item.id,
      name: item.name,
    };
  }

  buildContactListMember(item): ContactListMember {
    return {
      id: item.id,
      contactListId: item.ContactListId,
      contactId: item.ContactId,
      order: item.order,
      contact: item.Contact
        ? this._contactBuilder.buildContact(item.Contact)
        : null,
      isScheduled: item.isScheduled ?? false,
    };
  }

  buildNullExternalContactList(): ExternalContactList {
    return {
      id: null,
      name: null,
      notes: null,
      deletedAt: null,
      members: [],
      businessUnits: [],
    };
  }

  buildExternalContactList(item: any): ExternalContactList {
    const retVal = {
      id: item.id,
      name: item.name,
      notes: item.notes ? item.notes : null,
      deletedAt: item.deletedAt ? new Date(item.deletedAt) : null,
      updatedAt: item.updatedAt ? new Date(item.updatedAt) : null,
      updater: item.updater ? item.updater : null,
      members: item.ExternalContactListMembers
        ? item.ExternalContactListMembers.map((clm) => ({
            id: clm.id,
            name: clm.name,
            description: clm.description,
            phone: clm.phone,
            order: clm.order,
          }))
        : [],
      businessUnits: item.BusinessUnits
        ? item.BusinessUnits.map((bu) => ({
            id: bu.id,
            name: bu.name,
          }))
        : [],
    };

    return retVal;
  }

  buildNewMember(
    contactListId: string,
    contact: Contact,
    order: number
  ): ContactListMember {
    return {
      id: uuid(),
      contactListId: contactListId,
      contactId: contact.id,
      contact: contact,
      order: order,
      dirtyStatus: DirtyStatus.NEW,
      isScheduled: false,
    };
  }

  buildNewUpdater(contactListId: string, data: any): ContactListUpdater {
    return {
      id: uuid(),
      contactListId: contactListId,
      contactId: data.value,
      label: data.label,
      dirtyStatus: DirtyStatus.NEW,
    };
  }

  buildUpdateStatement(contactList: ContactList, changes: any) {
    const retVal = {
      id: contactList.id,
      memberCodes: changes.memberCodes
        ? changes.memberCodes.map((mc) => ({
            id: mc.id,
            code: mc.code,
          }))
        : [],
      notes: changes.notes ? changes.notes : null,
      name: changes.name ? changes.name : contactList.name,
      members: contactList.members
        ? contactList.members.map((m) => ({
            id: m.id,
            ContactListId: contactList.id,
            ContactId: m.contactId,
            order: m.order,
          }))
        : null,
      updaters: contactList.updaters
        ? contactList.updaters.map((m) => ({
            id: m.id,
            ContactListId: contactList.id,
            ContactId: m.contactId,
          }))
        : null,
      businessUnits: changes.businessUnits.map((bu) => {
        return {
          id: bu,
        };
      }),
    };

    if (changes.increment) {
      retVal['increment'] = changes.increment;
    }

    return retVal;
  }

  buildExternalContactListUpdateStatement(
    contactList: ExternalContactList,
    changes: any
  ) {
    const retVal = {
      id: contactList.id ? contactList.id : uuid(),
      members: contactList.members
        ? contactList.members.map((m) => ({
            id: m.id ? m.id : uuid(),
            ExternalContactListId: contactList.id,
            order: m.order,
            name: m.name,
            description: m.description,
            phone: m.phone,
            dirtyStatus: m.dirtyStatus ? m.dirtyStatus : 0,
          }))
        : null,
      businessUnits: contactList.businessUnits,
    };

    if (changes.name) {
      retVal['name'] = changes.name;
    }
    if (changes.businessUnits) {
      retVal['businessUnits'] = changes.businessUnits.map((bu: string) => ({
        id: bu,
      }));
    }
    retVal['notes'] = changes.notes ? changes.notes : contactList.notes;
    return retVal;
  }

  // TODOD: Member codes.
  buildGraphQLContactList(
    contactList: ContactList,
    contactListInput: any
  ): any {
    const retVal = {
      id: contactList.id,
      MemberCodes: contactListInput.memberCodes
        ? contactListInput.memberCodes.map((mc) => ({
            __typename: 'MemberCode',
            id: mc.id,
            code: mc.code,
            ContactListId: contactList.id,
          }))
        : [],
      notes: contactListInput.notes
        ? contactListInput.notes
        : contactList.notes,
      name: contactListInput.name ? contactListInput.name : contactList.name,
      Facilities: contactList.facilities
        ? contactList.facilities.map((f) => {
            return {
              ...this._facilityBuilder.buildGraphQLFacility(
                f,
                {},
                null,
                null,
                null,
                null,
                true
              ),
              __typename: 'Facility',
            };
          })
        : [],
      ContactListMembers: contactList.members
        ? contactList.members.map((m) => ({
            id: m.id,
            ContactListId: contactList.id,
            ContactId: m.contactId,
            order: m.order,
            __typename: 'ContactListMember',
          }))
        : null,
      ContactListUpdaters: contactList.updaters
        ? contactList.updaters.map((m) => ({
            id: m.id,
            ContactListId: contactList.id,
            ContactId: m.contactId,
            __typename: 'ContactListUpdater',
          }))
        : null,
      createdAt: contactList.createdAt ? contactList.createdAt : null,
      updatedAt: contactList.updatedAt ? contactList.updatedAt : null,
      deletedAt: contactList.deletedAt ? contactList.deletedAt : null,
    };

    return retVal;
  }

  buildGraphQLExternalContactList(
    contactList: ExternalContactList,
    contactListInput: any
  ): any {
    const retVal = {
      id: contactList.id,
      notes: contactListInput.notes
        ? contactListInput.notes
        : contactList.notes,
      name: contactListInput.name ? contactListInput.name : contactList.name,
      createdAt: contactList.createdAt
        ? contactList.createdAt.toISOString()
        : null,
      deletedAt: contactList.deletedAt
        ? contactList.deletedAt.toISOString()
        : null,
      updatedAt: contactList.updatedAt
        ? contactList.updatedAt.toISOString()
        : null,
      updater: contactList.updater ? contactList.updater : null,
      ExternalContactListMembers: contactList.members
        ? contactList.members.map((m) => ({
            id: m.id,
            ExternalContactListId: contactList.id,
            order: m.order,
            name: m.name,
            description: m.description,
            phone: m.phone,
            dirtyStatus: DirtyStatus.UPDATED,
            __typename: 'ExternalContactListMember',
          }))
        : null,
    };

    return retVal;
  }

  buildContactListSchedule(s: Schedule): Schedule {
    let cloned = { ...s };
    cloned.utcStartTime = utc(cloned.startTime).format('YYYY-MM-DDTHH:mm:ss');
    cloned.utcEndTime = utc(cloned.endTime).format('YYYY-MM-DDTHH:mm:ss');

    cloned.startTime = getCorrectedDate(cloned.utcStartTime);
    cloned.endTime = getCorrectedDate(cloned.utcEndTime);
    return cloned;
  }
}
