<div id="contact-details-container">
  <app-progress-spinner [enabled]="!contact"></app-progress-spinner>
  <p-blockUI [blocked]="saving === true || loading === true"></p-blockUI>
  @if (contact) {
    <div id="contact-details">
      <div id="contact-action-buttons">
        <div
        [className]="
          isUnavailable === true
            ? 'unavailable xtra-small-top-padding'
            : 'xtra-small-top-padding'
        "
          >
          <h2>
            {{ contact.firstName }} {{ contact.lastName }}
            @if (isUnavailable) {
              <span>{{
                "CONTACT.LABEL.UNAVAILABLE" | translate
              }}</span>
            }
          </h2>
        </div>
        <div class="no-padding">
          @if (canEdit || (canEditOwn && isOwnProfile)) {
            <div
              class="button-wrapper no-padding"
              >
              @if (showActionsDropdown(contact)) {
                <div>
                  <app-progress-spinner [enabled]="saving"></app-progress-spinner>
                </div>
              }
              @if (!showActionsDropdown(contact)) {
                <div>
                  <app-progress-spinner [enabled]="saving"></app-progress-spinner>
                </div>
              }
              @if (showActionsDropdown(contact)) {
                <div
                  class="no-padding-top-bottom align-left"
                  >
                  <p-dropdown
                    #dd
                    [options]="actions"
                    [(ngModel)]="selectedAction"
                    [ngModelOptions]="{ standalone: true }"
                    (onChange)="actionEvent($event, dd)"
                    [(ngModel)]="selectedAction"
                    [ngModelOptions]="{ standalone: true }"
                    styleClass="action-drop-down"
                  ></p-dropdown>
                </div>
              }
              @if (showActionsDropdown(contact)) {
                <div
                  id="save-btn"
                  class="no-padding-top-bottom"
                  >
                  <button
                    pButton
                    label="{{ 'COMMON.LABEL.BUTTONS.SAVE' | translate }}"
                    icon="fa fa-fw fa-save"
                    (click)="saveContact()"
                    [disabled]="!this.form.dirty || saving"
                  ></button>
                </div>
              }
              @if (!showActionsDropdown(contact)) {
                <div>
                  <button
                    pButton
                    label="{{ 'COMMON.LABEL.BUTTONS.SAVE' | translate }}"
                    icon="fa fa-fw fa-save"
                    (click)="saveContact()"
                    [disabled]="!this.form.dirty || saving"
                  ></button>
                </div>
              }
            </div>
          }
        </div>
      </div>
      <hr id="contact-scroll-divider" />
      <p-scrollPanel styleClass="contact-scroll-panel">
        @if (ifDeviceMatches([DeviceSize.LG, DeviceSize.XL])) {
          <p-tabView
            [activeIndex]="tabIndex"
            #tv
            (onChange)="loadFacilities($event)"
            >
            <p-tabPanel
          header="{{
            'CONTACT.LABEL.CONTACT_INFORMATION' | translate | uppercase
          }}"
              >
              <div>
                <app-contact-information-form
                  (goToContactEvent)="goToContact($event)"
                  [contact]="contact"
                  [form]="form"
                  [deviceSize]="getDeviceSize()"
                  [availableJobRoles]="availableJobRoles"
                  [availableCompanies]="availableCompanies"
                  [availableCountries]="availableCountries"
                  [validationMessages]="validationMessages"
                  [validationParams]="validationParams"
                  [startTime]="startTime"
                  [endTime]="endTime"
                  [showWhenUnavailable]="showWhenUnavailable"
                  [selectedAvailability]="selectedAvailability"
                  [modifyButtonLabel]="modifyAvailabilityButtonLabel"
                  [modifyButtonIcon]="modifyAvailabilityIcon"
                  [filteredContacts]="filteredContacts"
                  [selectedAvailability]="selectedAvailability"
                  (updateAvailabilityEvent)="updateAvailability($event)"
                  (deleteAvailabilityEvent)="deleteAvailability($event)"
                  [availableContactMethods]="availableContactMethods"
                  (modifyAvailabilityEvent)="modifyAvailability($event)"
                  (clearAvailabilityEvent)="clearAvailability()"
                  (supervisorSearchEvent)="supervisorSearch($event)"
                  (whenUnavailableSearchEvent)="supervisorSearch($event)"
                  [writeAccess]="canEdit || (canEditOwn && isOwnProfile())"
                  [canViewPII]="canViewPII"
                  (formPristineEvent)="clearAsteriskFromTab()"
                  #cif
                  >
                </app-contact-information-form>
              </div>
            </p-tabPanel>
            <p-tabPanel
          header="{{
            'CONTACT.LABEL.ASSOCIATED_FACILITIES' | translate | uppercase
          }}"
              >
              @if (facilitiesLoaded) {
                <app-contact-associate-facilities
                  [facilities]="facilities"
                  (openFacilityEvent)="openFacility($event)"
                  (openContactListEvent)="openContactList($event)"
                  >
                </app-contact-associate-facilities>
              }
              <app-progress-spinner
                [enabled]="!facilitiesLoaded"
              ></app-progress-spinner>
            </p-tabPanel>
          </p-tabView>
        }
        @if (ifDeviceMatches([DeviceSize.XS, DeviceSize.SM, DeviceSize.MD])) {
          <div
            >
            <app-contact-information-form
              (goToContactEvent)="goToContact($event)"
              [contact]="contact"
              [deviceSize]="getDeviceSize()"
              [form]="form"
              [availableJobRoles]="availableJobRoles"
              [availableCompanies]="availableCompanies"
              [availableCountries]="availableCountries"
              [validationMessages]="validationMessages"
              [validationParams]="validationParams"
              [startTime]="startTime"
              [endTime]="endTime"
              [selectedAvailability]="selectedAvailability"
              [canViewPII]="canViewPII"
              [modifyButtonLabel]="modifyAvailabilityButtonLabel"
              [modifyButtonIcon]="modifyAvailabilityIcon"
              [filteredContacts]="filteredContacts"
              [selectedAvailability]="selectedAvailability"
              (updateAvailabilityEvent)="updateAvailability($event)"
              (deleteAvailabilityEvent)="deleteAvailability($event)"
              [availableContactMethods]="availableContactMethods"
              (modifyAvailabilityEvent)="modifyAvailability($event)"
              (clearAvailabilityEvent)="clearAvailability()"
              (supervisorSearchEvent)="supervisorSearch($event)"
              [writeAccess]="canEdit || (canEditOwn && isOwnProfile())"
            ></app-contact-information-form>
          </div>
        }
        @if (ifDeviceMatches([DeviceSize.SM, DeviceSize.XS, DeviceSize.MD])) {
          <div
            >
            <p-panel
              header="{{ 'CONTACT.LABEL.ASSOCIATED_FACILITIES' | translate }}"
              [toggleable]="true"
              toggler="header"
              (onAfterToggle)="toggleFacilities($event)"
              [collapsed]="startCollapsed"
              >
              @if (facilitiesLoaded) {
                <app-contact-associate-facilities
                  [facilities]="facilities"
                  (openFacilityEvent)="openFacility($event)"
                  >
                </app-contact-associate-facilities>
              }
              <app-progress-spinner
                [enabled]="!facilitiesLoaded"
              ></app-progress-spinner>
            </p-panel>
          </div>
        }
      </p-scrollPanel>
    </div>
  }

  <app-audit-dialog-container
    [displayDialog]="displayAuditDialog"
    [auditHistory]="history"
    (dialogHiddenEvent)="auditDialogHidden()"
    [loading]="auditLoading"
  ></app-audit-dialog-container>
</div>
