import { Component, viewChild, inject, input, output } from '@angular/core';
import { FileUpload } from 'primeng/fileupload';
import { ScheduleImportRow } from 'src/app/model/contacts/schedule-import-row';
import { MessageService } from 'primeng/api';
import { isNullOrEmpty } from 'src/app/core/functions/common-functions';
import { ContactListApiService } from 'src/app/services/contact-list-api.service';
import { ContactList } from 'src/app/model/contacts/contact-list';
import { LogAndMessageService } from 'src/app/services/log-and-message.service';
import { ContactListScheduleImport } from 'src/app/model/contacts/contact-list-schedule-import';
import { Contact } from 'src/app/model/contacts/contact';
import moment from 'moment';

@Component({
    selector: 'app-schedule-import',
    templateUrl: './schedule-import.component.html',
    styleUrls: ['./schedule-import.component.scss'],
    standalone: false
})
export class ScheduleImportComponent {
  private _contactListAPI = inject(ContactListApiService);
  private _logAndMessage = inject(LogAndMessageService);

  readonly contactList = input<ContactList>(undefined);
  readonly fileUploadComponent = viewChild<FileUpload>('upload');

  readonly scheduleImported = output();
  readonly scheduleExported = output();

  lastUploadedFile: File;
  lastUploadTime: Date;
  importedRows: ScheduleImportRow[] = [];
  importedSchedules: ContactListScheduleImport[] = [];
  invalidSchedule: { header; body };

  saveEnabled: boolean = false;
  working: boolean = false;

  contacts: Contact[];
  adAccountNames: any[] = [];

  ngOnInit(): void {
    this.loadAdAccountNames();
  }

  handleFileUpload(event: { files: File[] }) {
    this.working = true;
    this.lastUploadedFile = event.files[0];
    this.lastUploadTime = new Date();
    this.lastUploadedFile
      .text()
      .then((fileText) => (this.importedRows = this.parseCSV(fileText)))
      .then(() => {
        let didAnyRowFailParse = this.importedRows.some(
          (row) => row.failedParse
        );
        if (!didAnyRowFailParse) {
          this.saveEnabled = true;
        }
      });
  }

  saveBoundChanges() {
    this.working = true;

    const importSchedules = this.importedRows.map((row) => ({
      adAccountName: row.adAccountName,
      isPrimary: row.primarySecondary.toLowerCase() === 'p' ? true : false,
      startTime:
        new Date(
          moment(row.startTime).format('YYYY-MM-DDTHH:mm:ss.SSS') + 'Z'
        ).getTime() / 1000,
      endTime:
        new Date(
          moment(row.endTime).format('YYYY-MM-DDTHH:mm:ss.SSS') + 'Z'
        ).getTime() / 1000,
      timezone: row.timezone,
    }));
    this._contactListAPI
      .importContactListSchedule(this.contactList().id, importSchedules)
      .pipe()
      .subscribe(
        ({ data }) => {
          this.working = false;
          this._logAndMessage.translateToSuccessMessage({
            bodyKey: 'COMMON.MESSAGES.SUCCESS.SAVED_SUCCESSFULLY',
            headerKey: 'COMMON.MESSAGES.HEADERS.SUBMIT',
          });
          this.scheduleImported.emit();
          // After we complete an import clear out the whole page
          this.fileUploadComponent().clear();
          this.lastUploadedFile = null;
          this.lastUploadTime = null;
          this.importedRows = null;
          this.saveEnabled = false;
        },
        (error) => {
          this.working = false;
          this._logAndMessage.translateToErrorMessage({
            headerKey: 'COMMON.MESSAGES.HEADERS.RETRIEVE_ERROR',
            bodyKey: 'COMMON.MESSAGES.ERROR.INVALID_SCHEDULE',
          });
        }
      );
  }

  cancelFile() {
    this.fileUploadComponent().clear();
    this.lastUploadedFile = null;
    this.lastUploadTime = null;
    this.importedRows = null;
    this.saveEnabled = false;
  }

  parseCSV(csvFileText: string): ScheduleImportRow[] {
    let results: ScheduleImportRow[] = [];
    const today = new Date();
    // Split the csv into rows
    let splitFile = csvFileText.trim().split('\r\n');
    let headerOK = true;
    splitFile.forEach((row, index) => {
      if (index === 0) {
        // Header row
        const expected_header =
          'User adAccountName,Primary or Secondary,Start Time,End Time,Time Zone';
        if (row !== expected_header) {
          console.error('CSV Header did not match expected format!', row);
          this._logAndMessage.translateToErrorMessage({
            headerKey: 'Error',
            bodyKey: 'CSV Header did not match expected format!',
          });
          headerOK = false;
        }
        return;
      }

      if (!headerOK) {
        this.cancelFile();
        return [];
      }

      let splitRow = row.split(',');

      let newScheduleImportRow: ScheduleImportRow = {
        adAccountName: splitRow[0],
        primarySecondary: splitRow[1],
        startTime: !moment(new Date(splitRow[2]), moment.ISO_8601).isValid()
          ? null
          : new Date(splitRow[2]),
        endTime: !moment(new Date(splitRow[3]), moment.ISO_8601).isValid()
          ? null
          : new Date(splitRow[3]),
        timezone: splitRow[4],
        failedParse: false,
        failMessage: '',
      };

      if (!splitRow[0]) {
        newScheduleImportRow.failedParse = true;
        newScheduleImportRow.failMessage += 'User adAccountName is empty! ';
        this.saveEnabled = false;
      }
      if (!splitRow[1]) {
        newScheduleImportRow.failedParse = true;
        (newScheduleImportRow.failMessage += `Primary or Secondary is empty! `),
          (this.saveEnabled = false);
      }

      if (isNullOrEmpty(newScheduleImportRow.startTime)) {
        newScheduleImportRow.failedParse = true;
        newScheduleImportRow.failMessage +=
          'Found startTime value that could not be parsed to a valid Date! ';
        this.saveEnabled = false;
      }

      if (isNullOrEmpty(newScheduleImportRow.endTime)) {
        newScheduleImportRow.failedParse = true;
        console.log('Failed.');
        newScheduleImportRow.failMessage +=
          'Found endTime value that could not be parsed to a valid Date! ';
        this.saveEnabled = false;
      }

      if (
        this.adAccountNames.indexOf(newScheduleImportRow.adAccountName) === -1
      ) {
        newScheduleImportRow.failedParse = true;
        newScheduleImportRow.failMessage = `User adAccountName ${newScheduleImportRow.adAccountName} is not a member of this contact list! `;
        this.saveEnabled = false;
      }

      if (
        !(newScheduleImportRow.primarySecondary.toLowerCase() === 'p') &&
        !(newScheduleImportRow.primarySecondary.toLowerCase() === 's')
      ) {
        newScheduleImportRow.failedParse = true;
        newScheduleImportRow.failMessage +=
          'Found invalid Primary or Secondary value! ';
        this.saveEnabled = false;
      }

      if (
        newScheduleImportRow.endTime <= today &&
        !isNullOrEmpty(newScheduleImportRow.endTime)
      ) {
        newScheduleImportRow.failedParse = true;
        newScheduleImportRow.failMessage += `The end time has already passed. `;
        this.saveEnabled = false;
      }

      if (
        newScheduleImportRow.startTime <= today &&
        !isNullOrEmpty(newScheduleImportRow.startTime)
      ) {
        newScheduleImportRow.failedParse = true;
        (newScheduleImportRow.failMessage += `The start time has already passed. `),
          (this.saveEnabled = false);
      }

      if (
        newScheduleImportRow.endTime <= newScheduleImportRow.startTime &&
        !isNullOrEmpty(newScheduleImportRow.startTime) &&
        !isNullOrEmpty(newScheduleImportRow.endTime)
      ) {
        newScheduleImportRow.failedParse = true;
        newScheduleImportRow.failMessage += `The end time occurs before the start time. `;
        this.saveEnabled = false;
      }

      results.push(newScheduleImportRow);
    });

    return results;
  }

  loadAdAccountNames() {
    this.contacts = this.contactList().members.map((member) => member.contact);
    this.contacts.forEach((c) => {
      let adAccountName = c.raw.adAccountName;
      this.adAccountNames.push(adAccountName);
    });

    return this.adAccountNames;
  }

  downloadSchedule() {
    this.scheduleExported.emit();
  }
}
