import { Component, OnInit, input, output } from '@angular/core';

@Component({
    selector: 'app-contact-mobile-filter',
    templateUrl: './contact-mobile-filter.component.html',
    styleUrls: ['./contact-mobile-filter.component.scss'],
    standalone: false
})
export class ContactMobileFilterComponent implements OnInit {
  readonly showMobileFilter = input<boolean>(undefined);
  readonly mobileFilterEvent = output<any>();

  name: string;
  supervisor: string;

  constructor() { }

  ngOnInit() {  }

  mobileFilter() {
    this.mobileFilterEvent.emit({name: this.name, supervisor: this.supervisor});
  }

  clear() {
    this.name = null;
    this.supervisor = null;
    this.mobileFilter();
  }

}
