@if (showMobileFilter()) {
  <div
    class="ui-g-12 ui-fluid mobile-filter">
    <div class="ui-g-12">
      <label class="mobile-filter-label"
      [translate]="'LOCATION.LABEL.STATE'"></label>
    </div>
    <div class="ui-g-12">
      <input pInputText
        type="text"
        [(ngModel)]="state" />
      </div>
      <div class="ui-g-12">
        <label class="mobile-filter-label"
        [translate]="'LOCATION.LABEL.COUNTY'"></label>
      </div>
      <div class="ui-g-12">
        <input pInputText
          type="text"
          [(ngModel)]="county" />
        </div>
        <div class="ui-g-12">
          <label class="mobile-filter-label"
          [translate]="'LOCATION.LABEL.TOWNSHIP'"></label>
        </div>
        <div class="ui-g-12">
          <input pInputText
            type="text"
            [(ngModel)]="township" />
          </div>
          <div class="ui-g-12">
            <button type="button"
              pButton
              icon="fa fa-search"
              iconPos="left"
              label="{{'COMMON.LABEL.BUTTONS.RETRIEVE' | translate}}"
              class="nav-button filter-panel-button"
            (click)="mobileFilter()"></button>
          </div>
          <div class="ui-g-12">
            <button type="button"
              pButton
              icon="fa fa-fw fa-eraser"
              iconPos="left"
              label="{{'COMMON.LABEL.BUTTONS.CLEAR' | translate}}"
              class="nav-button filter-panel-button"
            (click)="clear()"></button>
          </div>
        </div>
      }