import { Component, OnInit, output } from '@angular/core';

@Component({
    selector: 'app-external-contact-list-mobile-filter',
    templateUrl: './external-contact-list-mobile-filter.component.html',
    styleUrls: ['./external-contact-list-mobile-filter.component.scss'],
    standalone: false
})
export class ExternalContactListMobileFilterComponent implements OnInit {

  readonly mobileFilterEvent = output<any>();

  name: string;

  constructor() { }

  ngOnInit() {  }

  mobileFilter() {
    this.mobileFilterEvent.emit({name: this.name});
  }

  clear() {
    this.name = null;
    this.mobileFilter();
  }
}
