import { ChangeDetectorRef, Component, OnDestroy, OnInit, viewChild, inject } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmationService, SelectItem } from 'primeng/api';
import { forkJoin, Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { DELETE_LIST, VIEW_AUDIT } from 'src/app/constants/action-constants';
import {
  CONTACT_LIST_SEARCH_CONTAINER,
  FACILITY_DETAIL_CONTAINER,
} from 'src/app/constants/common.constants';
import { CONTACT_LIST_TAB_PREFIX } from 'src/app/constants/contact-constants';
import { DetailsContainer } from 'src/app/core/containers/details-container';
import {
  buildAuditHistory,
  dynamicSort,
  isNullOrEmptyString,
} from 'src/app/core/functions/common-functions';
import { TabService } from 'src/app/core/services/tab.service';
import { BreadCrumb } from 'src/app/model/common/bread-crumb';
import { DirtyStatus } from 'src/app/model/common/dirty-status';
import { Contact } from 'src/app/model/contacts/contact';
import { ContactList } from 'src/app/model/contacts/contact-list';
import { BreadCrumbBuilderService } from 'src/app/services/breadcrumb-builder.service';
import { ContactApiService } from 'src/app/services/contact-api.service';
import { AuthApiService } from 'src/app/services/auth-api.service';
import { ContactBuilderService } from 'src/app/services/contact-builder.service';
import { ContactListApiService } from 'src/app/services/contact-list-api.service';
import { ContactListBuilderService } from 'src/app/services/contact-list-builder.service';
import { ContactListTableService } from 'src/app/services/contact-list-table.service';
import { DeviceService } from 'src/app/services/device.service';
import { FacilityApiService } from 'src/app/services/facility-api.service';
import { FacilityBuilderService } from 'src/app/services/facility-builder.service';
import { FacilityTableService } from 'src/app/services/facility-table.service';
import { LoadingService } from 'src/app/services/loading.service';
import { LocationApiService } from 'src/app/services/location-api.service';
import { LogAndMessageService } from 'src/app/services/log-and-message.service';
import { ContactListSimpleViewContainerComponent } from '../contact-list-simple-view/contact-list-simple-view-container.component';
import { ScheduleFormComponent } from '../../components/schedule-form/schedule-form.component';
import { Schedule } from 'src/app/model/contacts/contact-list-scheduler';
import {
  CalendarOptions,
  DateSelectArg,
  EventClickArg,
} from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin, { DateClickArg } from '@fullcalendar/interaction';
import {
  getDateInTimeZone,
  isNullOrUndefined,
  sortByProperty,
} from 'src/app/utils/utils';
import { AuthService } from 'src/app/services/auth.service';
import moment from 'moment';
import {
  AutoCompleteCompleteEvent,
  AutoCompleteSelectEvent,
} from 'primeng/autocomplete';
import { DataView } from 'primeng/dataview';
import { TabDto } from 'src/app/model/common/tab-dto';
import { TabViewChangeEvent } from 'primeng/tabview';
import { Facility } from 'src/app/model/locations/facility';
import { Dropdown, DropdownChangeEvent } from 'primeng/dropdown';
import { CLAIMS } from 'src/app/constants/auth-constants';

@Component({
    selector: 'app-contact-list-detail-view-container',
    templateUrl: './contact-list-detail-view-container.component.html',
    styleUrls: ['./contact-list-detail-view-container.component.scss'],
    standalone: false
})
export class ContactListDetailViewContainerComponent
  extends DetailsContainer
  implements OnInit, OnDestroy
{
  private _contactListTable = inject(ContactListTableService);
  private _breadCrumbService = inject(BreadCrumbBuilderService);
  private _contactListApi = inject(ContactListApiService);
  private _contactListBuilder = inject(ContactListBuilderService);
  private _fb = inject(UntypedFormBuilder);
  private _logAndMessage = inject(LogAndMessageService);
  private _authApi = inject(AuthApiService);
  private _auth = inject(AuthService);
  protected _translateService: TranslateService;
  protected _deviceService: DeviceService;
  protected _confirmationService: ConfirmationService;
  protected _contactApi: ContactApiService;
  private _contactBuilder = inject(ContactBuilderService);
  protected _locationApi: LocationApiService;
  protected _facilityApi: FacilityApiService;
  private _facilityBuilder = inject(FacilityBuilderService);
  protected _facilityTableService: FacilityTableService;
  private _cdRef = inject(ChangeDetectorRef);
  private _loader = inject(LoadingService);

  readonly view = viewChild<ContactListSimpleViewContainerComponent>('sview');
  readonly scheduleForm = viewChild.required<ScheduleFormComponent>('scheduleForm');
  readonly dataView = viewChild.required<DataView>('dv');

  DirtyStatus = DirtyStatus;
  contactList: ContactList;
  memberContactArray: Contact[];
  loaded = false;
  facilities: any[];
  contactListFacilities: any[] = [];
  filteredFacilities: any[];

  addToFacilityForm: UntypedFormGroup;
  saving = false;

  backToSearchResult: BreadCrumb;
  listBreadCrumb: BreadCrumb;

  canDelete = false;
  canCreate = false;
  canSchedule = false;
  canViewUpdaters = false;
  allContacts: Contact[];
  filteredContacts: any[];
  selectedUpdater: any;

  actions: SelectItem[];
  selectedAction: any;
  locations: any[];
  tabIndex = 0;

  breadCrumbSub: Subscription;
  deleteEntryMsg: string;
  deleteEntryHeader: string;
  okBtnLabel: string;
  cancelBtnLabel: string;
  deleteHeader: string;

  scheduleFormVisible: boolean = false;
  scheduleFormType: string = 'Create';
  disableDelete: boolean = false;
  contactListSchedule: Schedule[];
  selectedSchedule: any;
  today = new Date();
  events: any[] = [];

  calendarOptions: CalendarOptions = {
    initialView: 'dayGridMonth',
    aspectRatio: 3,
    selectable: true,
    plugins: [dayGridPlugin, interactionPlugin],
    dateClick: this.handleDateClick.bind(this),
    eventClick: this.handleEventClick.bind(this),
    select: this.handleSelectable.bind(this),
  };

  constructor() {
    const _translateService = inject(TranslateService);
    const _deviceService = inject(DeviceService);
    const _confirmationService = inject(ConfirmationService);
    const _contactApi = inject(ContactApiService);
    const _locationApi = inject(LocationApiService);
    const _facilityApi = inject(FacilityApiService);
    const _facilityTableService = inject(FacilityTableService);

    super(
      _translateService,
      _deviceService,
      _confirmationService,
      _locationApi,
      _contactApi,
      _facilityTableService,
      _facilityApi
    );
    this._translateService = _translateService;
    this._deviceService = _deviceService;
    this._confirmationService = _confirmationService;
    this._contactApi = _contactApi;
    this._locationApi = _locationApi;
    this._facilityApi = _facilityApi;
    this._facilityTableService = _facilityTableService;

  }
  ngOnInit(): void {
    this.setupTabClosingSubscription();
    this.setupBreadCrumbSub();
    if (this._loader.isLoaded()) {
      this.initialize();
    } else {
      this._loader.loadingFinishedEvent.subscribe((event) => {
        this.initialize();
      });
    }

    this._contactApi
      .findContacts()
      .pipe(take(1))
      .subscribe(({ data }) => {
        const clone = [...data.getContacts];
        this.allContacts = clone
          .map((c) => this._contactBuilder.buildContact(c))
          .sort(dynamicSort('firstName', 1));
      });
  }

  ngOnDestroy(): void {
    if (this.tabClosingSub) {
      this.tabClosingSub.unsubscribe();
    }
    if (this.breadCrumbSub) {
      this.breadCrumbSub.unsubscribe();
    }
  }

  setupBreadCrumbSub(): void {
    this.breadCrumbSub =
      this._breadCrumbService.breadCrumbSelectedEvent.subscribe(
        (breadCrumb) => {
          this._breadCrumbService.removeBreadCrumb(this.listBreadCrumb);
          const tab = TabService.getInstance().buildNewTab(
            CONTACT_LIST_SEARCH_CONTAINER,
            true
          );
          TabService.getInstance().setMobileTab(tab);
        }
      );
  }

  initialize(): void {
    const id = this.id;
    this.contactList = this._contactListTable.getSelected();
    this.memberContactArray = this.contactList?.members.map(
      (member) => member.contact
    );
    if (!isNullOrUndefined(this.contactList)) {
      this.facilities = [...this.contactList.facilities];
      this.loadScreenData();
    } else if (id) {
      // use network query to find contact list by id.
      this._contactListApi
        .getContactListWithSortedMembers(id)
        .pipe(take(1))
        .subscribe(
          (list: ContactList) => {
            this.contactList = list;
            this.memberContactArray = this.contactList?.members.map(
              (member) => member.contact
            );
            this.memberContactArray = this.contactList?.members.map(
              (member) => member.contact
            );
            this.doCheckComplete = false;
            this.facilities = [...this.contactList.facilities];
            this.loadScreenData();
            this.loading = false;
          },
          (error) => {
            console.error(error);
            this._logAndMessage.translateToErrorMessage({
              headerKey: 'COMMON.MESSAGES.HEADERS.RETRIEVE_ERROR',
              bodyKey: 'COMMON.MESSAGES.ERROR.RETRIEVE_ERROR_MESSAGE',
            });
          }
        );
    } else {
      // Close this tab since the list is invalid. This will set the active
      // tab to the tab the user was on previously.
      this._logAndMessage.errorLogOnly('Contact List: ID from route was null');
      if (this._deviceService.isMobile()) {
        const tab = TabService.getInstance().buildNewTab(
          CONTACT_LIST_SEARCH_CONTAINER,
          true
        );
        TabService.getInstance().setMobileTab(tab);
      } else {
        const index = TabService.getInstance().getActiveIndex();
        TabService.getInstance().closeTab(index);
      }
    }
  }

  loadScreenData(): void {
    if (this._deviceService.isMobile()) {
      this._translateService
        .get('CONTACT.SCREEN.CONTACT_LIST')
        .subscribe((label) => {
          this._breadCrumbService.resetAndAddBreadCrumb(
            new BreadCrumb(label, null, true)
          );
          if (this.contactList) {
            this.listBreadCrumb = new BreadCrumb(
              this.contactList.name,
              null,
              false
            );
            this._breadCrumbService.addBreadCrumb(this.listBreadCrumb);
          }
        });
    } else {
      const header = CONTACT_LIST_TAB_PREFIX + this.contactList.name;
      TabService.getInstance().updateActiveTabLabel(header);
    }

    this.loadDropDownData();
    this.loadCalendarEvents();

    this.findUpdaters();
    this.loadFacilities();

    this._cdRef.markForCheck();

    this.addToFacilityForm = this._fb.group({
      state: [null, Validators.required],
      county: [null, Validators.required],
      township: [null, Validators.required],
      facility: [null],
    });

    this.canCreate = this._authApi.doesUserHaveAllClaimsFromList([
      CLAIMS.CONTACTS.CONTACT_LISTS.CREATE_CONTACT_LISTS,
    ]);
    this.canDelete = this._authApi.doesUserHaveAllClaimsFromList([
      CLAIMS.CONTACTS.CONTACT_LISTS.DELETE_CONTACT_LIST,
    ]);
    this.canViewUpdaters = this._authApi.doesUserHaveAllClaimsFromList([
      CLAIMS.CONTACTS.CONTACT_LISTS.VIEW_CONTACT_LIST_UPDATERS,
    ]);

    this.updateActionsList();

    if (this._authApi.doesUserHaveAllClaimsFromList([CLAIMS.CONTACTS.CONTACT_LISTS.SCHEDULE_CONTACT_LISTS])) {
      this.canSchedule = true;
    } else {
      this._contactApi
        .getContactByEmail(this._auth.getEmail())
        .pipe(take(1))
        .subscribe((data: Contact) => {
          const id = data.id;
          const onList = this.contactList.members.some(
            (m) => m.contactId === id
          );
          const anUpdater = this.contactList.updaters.some(
            (u) => u.contactId === id
          );
          if (!this.canSchedule) {
            this.canSchedule =
              (onList &&
                this._authApi.doesUserHaveAllClaimsFromList([
                  CLAIMS.CONTACTS.CONTACT_LISTS.SCHEDULE_OWN_CONTACT_LISTS,
                ])) ||
              anUpdater;
          }
        });

      if (
        this._authApi.doesUserHaveAllClaimsFromList([
          CLAIMS.CONTACTS.CONTACT_LISTS.SCHEDULE_DIRECT_REPORTS_CONTACT_LISTS,
        ])
      ) {
        this._contactApi
          .queryForWhoReportsToMe(this._auth.getEmail())
          .pipe(take(1))
          .subscribe(({ data }) => {
            const clone = { ...data };
            const reportingIds = clone.queryWhoReportsToMe.map(
              (report) => report.id
            );
            const reportOnList = this.contactList.members.some((m) =>
              reportingIds.includes(m.contactId)
            );
            if (reportOnList) {
              this.canSchedule = true;
            }
          });
      }
    }

    this._cdRef.markForCheck();
  }

  findUpdaters(): void {
    const contactIdArray = this.contactList.updaters.map((m) => m.contactId);
    let contactArray = [];
    this._contactApi
      .findContacts()
      .pipe(take(1))
      .subscribe(({ data }) => {
        const clone = [...data.getContacts];
        const innerArray = clone.filter(
          (c) => contactIdArray.indexOf(c.id) > -1
        );
        contactArray = [
          ...innerArray.map((c) => this._contactBuilder.buildContact(c)),
        ];
        const memberUpdates = [];
        contactArray.forEach((c) => {
          const member = this.contactList.updaters.filter(
            (m) => m.contactId === c.id
          );
          if (member.length > 0) {
            const newMember = Object.assign({}, member[0]);
            newMember.label = c.firstName + ' ' + c.lastName;
            memberUpdates.push(Object.assign({}, newMember));
          }
        });
        this.contactList.updaters = [...memberUpdates];
        this.contactList = Object.assign({}, this.contactList);
        this.memberContactArray = this.contactList?.members.map(
          (member) => member.contact
        );
        this.loaded = true;
      });
  }

  facilitySearch($event: AutoCompleteCompleteEvent, type): void {
    this.filteredFacilities = [...this.facilities].sort(dynamicSort(type, 1));
    if (
      !isNullOrUndefined($event) &&
      !isNullOrUndefined($event.query) &&
      !isNullOrUndefined(type)
    ) {
      this.filteredFacilities = this.filteredFacilities.filter((f) => {
        if (!isNullOrUndefined(f[type])) {
          return (
            f[type].toLowerCase().startsWith($event.query.toLowerCase()) &&
            f[type].trim() !== ''
          );
        }
      });
    }

    const state = this.addToFacilityForm.controls['state'].value;
    const county = this.addToFacilityForm.controls['county'].value
      ? this.addToFacilityForm.controls['county'].value.county
      : null;
    const township = this.addToFacilityForm.controls['township'].value
      ? this.addToFacilityForm.controls['township'].value.township
      : null;
    this.filteredFacilities = this.filteredFacilities.filter((f) => {
      return (
        (!isNullOrEmptyString(state)
          ? f.state.includes(state)
          : !isNullOrEmptyString(f.state)) &&
        (!isNullOrEmptyString(county)
          ? f.county.includes(county)
          : !isNullOrEmptyString(f.county)) &&
        (!isNullOrEmptyString(township)
          ? f.township.includes(township)
          : !isNullOrEmptyString(f.township))
      );
    });
  }

  selectFacility($event: AutoCompleteSelectEvent): void {
    const interior = $event.value;
    this.addToFacilityForm.patchValue({
      facility: interior,
      state: interior.state,
      county: { county: interior.county, state: interior.state },
      township: { township: interior.township },
    });
  }

  activeTabChanged($event: TabViewChangeEvent): void {
    if (!isNullOrUndefined($event)) {
      this.tabIndex = $event.index;
      // When this function gets called with an $event, it means we have called it from a tab change
      // if we are changing tabs we need to resize the calendar because otherwise its rendering appears to break
      // seems like an issue with the fullcalendar component but this looks to solve it
      setTimeout(() => {
        window.dispatchEvent(new Event('resize'));
      }, 1);
    }
  }

  loadFacilities(): void {
    this._facilityApi
      .getFacilities()
      .pipe(take(1))
      .subscribe((data) => {
        const clone = [...data];
        this._locationApi
          .findLocations()
          .pipe(take(1))
          .subscribe((locs) => {
            const locClone = Object.assign({}, locs);
            this.locations = locClone.data.getLocations;
            this.facilities = clone.map((f) =>
              this._facilityBuilder.buildFacility(f, this.locations)
            );
            this.contactListFacilities = this.facilities.filter((f) =>
              this.contactList.facilities.map((cf) => cf.id).includes(f.id)
            );
          });
      });
  }

  addContactListToFacility(): void {
    this.saving = true;
    const raw = this.addToFacilityForm.getRawValue();
    const array = this.facilities
      .filter((f) => f.id === raw.facility.id)
      .map((f) => this._facilityBuilder.buildFacility(f, [...this.locations]));
    if (array.length > 0) {
      const fac = Object.assign({}, array[0]);
      this._facilityApi
        .updateFacilityFromContactList(fac.id, this.contactList.id, this.locations)
        .subscribe(
          (data: Facility) => {
            let clone = Object.assign({}, data);
            const updatedFac = Object.assign(
              {},
              data
            );
            this._facilityTableService.setBuiltGqlForFacility(null);
            this._logAndMessage.translateToSuccessMessage({
              bodyKey: 'COMMON.MESSAGES.SUCCESS.SAVED_SUCCESSFULLY',
              headerKey: 'COMMON.MESSAGES.HEADERS.SUBMIT',
            });
            this.saving = false;
            this.contactList.facilities = [
              ...this.contactList.facilities,
              updatedFac,
            ];
            this.contactListFacilities = [
              ...this.facilities.filter((f) =>
                this.contactList.facilities.map((cf) => cf.id).includes(f.id)
              ),
            ];
            this.contactList = Object.assign({}, this.contactList);
            this.memberContactArray = this.contactList?.members.map(
              (member) => member.contact
            );
            this.addToFacilityForm.reset();
            this._cdRef.markForCheck();
            clone = null;
          },
          (error) => {
            console.error(error);
            this.saving = false;
            this.addToFacilityForm.reset();
          }
        );
    }
  }

  deleteContactListFromFacility($event: string): void {
    this.saving = true;
    const array = this.facilities.filter((f: any) => f.id === $event);
    if (array.length > 0) {
      const fac = array[0];
      this._facilityApi
      .updateFacilityFromContactList(fac.id, null, this.locations)
        .subscribe(
          (data: Facility) => {
            this._facilityTableService.setBuiltGqlForFacility(null);
            this._logAndMessage.translateToSuccessMessage({
              bodyKey: 'COMMON.MESSAGES.SUCCESS.SAVED_SUCCESSFULLY',
              headerKey: 'COMMON.MESSAGES.HEADERS.SUBMIT',
            });
            const idx = this.contactList.facilities.findIndex(
              (f) => f.id === fac.id
            );
            this.contactList.facilities.splice(idx, 1);
            this.contactList.facilities = [...this.contactList.facilities];
            this.contactList = Object.assign({}, this.contactList);
            this.memberContactArray = this.contactList?.members.map(
              (member) => member.contact
            );
            this.contactListFacilities = [
              ...this.facilities.filter((f) =>
                this.contactList.facilities.map((cf) => cf.id).includes(f.id)
              ),
            ];
            this.saving = false;
            this._cdRef.markForCheck();
          },
          (error) => {
            console.error(error);
            this.saving = false;
          }
        );
    }
  }

  openFacility(fac: Facility): void {
    if (fac && fac.id) {
      const tab = TabService.getInstance().buildNewTab(
        FACILITY_DETAIL_CONTAINER,
        true,
        null,
        fac.id
      );
      if (this._deviceService.isMobile()) {
        TabService.getInstance().setMobileTab(tab);
      } else {
        TabService.getInstance().openTab(tab);
      }
    }
  }

  contactSearch($event: AutoCompleteCompleteEvent): void {
    if ($event.query) {
      this.filteredContacts = this.allContacts
        .filter((c) =>
          (c.firstName + ' ' + c.lastName)
            .toLowerCase()
            .startsWith($event.query.toLowerCase())
        )
        .filter((c: Contact) => c.inactiveAdAccount === false)
        .map((c) => ({ label: c.firstName + ' ' + c.lastName, value: c.id }))
        .sort(dynamicSort('label', 1));
    } else {
      this.filteredContacts = this.allContacts
        .map((c) => ({ label: c.firstName + ' ' + c.lastName, value: c.id }))
        .sort(dynamicSort('label', 1));
    }
  }

  addContactAsUpdater(): void {
    if (this.selectedUpdater) {
      this.contactList.updaters = [
        ...this.contactList.updaters,
        this._contactListBuilder.buildNewUpdater(
          this.contactList.id,
          this.selectedUpdater
        ),
      ];
      this.contactList = Object.assign({}, this.contactList);
      this.memberContactArray = this.contactList?.members.map(
        (member) => member.contact
      );
      const view = this.view();
      if (view) {
        this.saving = true;
        view.saveContactList();
      }
    }
    this.selectedUpdater = null;
    this.saving = false;
    this._cdRef.markForCheck();
  }

  removeContactAsUpdater(id: string): void {
    if (!id) {
      console.error(
        'Attempted to remove contact as updater without supplying id!'
      );
      return;
    }

    this._confirmationService.confirm({
      header: 'Remove Updater?',
      icon: 'fa fa-question-circle',
      acceptVisible: true,
      rejectVisible: true,
      acceptLabel: this.okBtnLabel,
      rejectLabel: this.cancelBtnLabel,
      message: 'Are you sure you want to remove this contact as an updater?',
      accept: () => {
        const idx = this.contactList.updaters.findIndex((u) => u.id === id);
        this.contactList.updaters.splice(idx, 1);
        const view = this.view();
        if (view) {
          this.saving = true;
          view.saveContactList();
        }
        this.saving = false;
      },
      reject: () => {},
    });
  }

  updateActionsList(): void {
    const selectAction$ = this._translateService.get(
      'COMMON.LABEL.SELECT_ACTION'
    );
    const viewAudit$ = this._translateService.get(
      'COMMON.LABEL.VIEW_AUDIT_INFO'
    );
    const deleteMsg$ = this._translateService.get(
      'CONTACT.LABEL.DELETE_CONTACT_LIST'
    );

    let select,
      audit,
      deleteMsg = null;
    forkJoin([selectAction$, viewAudit$, deleteMsg$])
      .pipe(take(1))
      .subscribe((messages) => {
        select = messages[0];
        audit = messages[1];
        deleteMsg = messages[2];
        this.actions = [
          { label: select, value: null },
          { label: audit, value: VIEW_AUDIT },
        ];

        if (this.canDelete) {
          this.actions.push({ label: deleteMsg, value: DELETE_LIST });
        }
        // default actions here
        this.actions = [...this.actions];
        this.selectedAction = null;
      });
  }

  actionEvent($event: DropdownChangeEvent, object?: Dropdown): void {
    if ($event.value) {
      this.selectedAction = null;
      if ($event.value) {
        if ($event.value === VIEW_AUDIT) {
          this.displayAuditDialog = true;
          this.auditLoading = true;
          this._contactListApi
            .findContactListHistory(this.contactList.id)
            .pipe(take(1))
            .subscribe(
              ({ data }) => {
                const clone = Object.assign({}, data);
                this.history = [
                  ...buildAuditHistory(clone.getContactListHistory),
                ];
                this._cdRef.markForCheck();
              },
              (error) => {
                this.auditLoading = false;
              },
              () => {
                this.auditLoading = false;
              }
            );
        }

        if ($event.value === DELETE_LIST) {
          this.deleteContactList();
        }
      }

      if (!isNullOrUndefined(object)) {
        object.clear(null);
      }
    }
    this._cdRef.markForCheck();
  }

  deleteContactList(): void {
    const deleteEntryMsg$ = this._translateService.get(
      'CONTACT.MESSAGES.CONFIRMATION.DELETE_CONTACT_LIST'
    );
    const ok$ = this._translateService.get('COMMON.LABEL.BUTTONS.YES');
    const cancel$ = this._translateService.get('COMMON.LABEL.BUTTONS.NO');
    const deleteEntryHeader$ = this._translateService.get(
      'CONTACT.MESSAGES.HEADERS.DELETE_CONTACT_LIST'
    );
    forkJoin([ok$, cancel$, deleteEntryMsg$, deleteEntryHeader$]).subscribe(
      (messages) => {
        this.okBtnLabel = messages[0];
        this.cancelBtnLabel = messages[1];
        this.deleteEntryMsg = messages[2];
        this.deleteEntryHeader = messages[3];
      }
    );

    this._confirmationService.confirm({
      header: this.deleteEntryHeader,
      icon: 'fa fa-question-circle',
      acceptVisible: true,
      rejectVisible: true,
      acceptLabel: this.okBtnLabel,
      rejectLabel: this.cancelBtnLabel,
      message: this.deleteEntryMsg,
      accept: () => {
        const id = this.contactList.id;
        this.saving = true;
        this._contactListApi
          .deleteContactList(id, this.contactList)
          .pipe(take(1))
          .subscribe(
            ({ data }) => {
              this._logAndMessage.translateToSuccessMessage({
                bodyKey: 'COMMON.MESSAGES.SUCCESS.SAVED_SUCCESSFULLY',
                headerKey: 'COMMON.MESSAGES.HEADERS.SUBMIT',
              });
              this.saving = false;
            },
            (error) => {
              this.saving = false;
              this._logAndMessage.errorLogOnly(error);
              this._logAndMessage.translateToErrorMessage({
                headerKey: 'COMMON.MESSAGES.HEADERS.DELETE_ERROR',
                bodyKey: 'COMMON.MESSAGES.ERROR.DELETE_ERROR_MSG',
              });
            },
            () => {
              if (this._deviceService.isMobile()) {
                const tab = TabService.getInstance().buildNewTab(
                  CONTACT_LIST_SEARCH_CONTAINER,
                  true
                );
                TabService.getInstance().setMobileTab(tab);
              } else {
                const index = TabService.getInstance().getActiveIndex();
                TabService.getInstance().closeTab(index);
              }
            }
          );
      },
      reject: () => {},
    });
  }

  loadCalendarEvents(): void {
    this.loading = true;
    this._contactListApi
      .getContactListSchedules(this.contactList.id)
      .pipe()
      .subscribe(({ data }) => {
        this.events = [];
        this.contactListSchedule = [...data.getContactListSchedules];
        this.contactListSchedule.forEach((s) => {
          let timeConvertedSchedule =
            this._contactListBuilder.buildContactListSchedule(s);
          this.events.push({
            title:
              (s.isPrimary ? 'Primary: ' : 'Secondary: ') +
              s.contact.firstName +
              ' ' +
              s.contact.lastName,
            start: timeConvertedSchedule.startTime,
            end: timeConvertedSchedule.endTime,
            utcStart: timeConvertedSchedule.utcStartTime,
            utcEnd: timeConvertedSchedule.utcEndTime,
            ...s,
          });
        });
        this.calendarOptions.events = this.events;
        this.loading = false;
      });
  }

  handleDateClick(arg: DateClickArg): void {
    if (this.canSchedule) {
      const startDate = new Date(arg.dateStr + ' ');
      const todayClone = this.clearTimeFromDate(this.today);

      if (startDate >= todayClone) {
        this.scheduleFormVisible = true;
        this.onDateSelected(arg);
      } else {
        this._logAndMessage.translateToErrorMessage({
          headerKey: 'CONTACT.MESSAGES.HEADERS.INVALID_DATES',
          bodyKey: 'CONTACT.MESSAGES.WARN.INVALID_DATE_SELECTION',
        });
      }
    }
  }

  handleEventClick($event: EventClickArg): void {
    if (this.canSchedule) {
      this.onEventSelected($event);
      this.scheduleFormVisible = true;
    }
  }

  handleSelectable(arg: DateSelectArg): void {
    if (this.canSchedule) {
      const startDate = new Date(arg.start);
      const endDate = new Date(arg.end);
      endDate.setDate(endDate.getDate() - 1);
      const todayClone = this.clearTimeFromDate(this.today);

      if (startDate.toDateString() === endDate.toDateString()) {
        return;
      }

      if (startDate >= todayClone) {
        this.scheduleFormVisible = true;
        this.onDatesSelected(arg);
      } else {
        this._logAndMessage.translateToErrorMessage({
          headerKey: 'CONTACT.MESSAGES.HEADERS.INVALID_DATES',
          bodyKey: 'CONTACT.MESSAGES.WARN.INVALID_DATE_SELECTION',
        });
      }
    }
  }

  clearTimeFromDate(datetime: Date): Date {
    let clone = new Date(datetime);
    clone.setHours(0);
    clone.setMinutes(0);
    clone.setSeconds(0);
    clone.setMilliseconds(0);
    return clone;
  }

  onScheduleFormHidden(): void {
    this.scheduleFormVisible = false;
  }

  onDateSelected(arg: any): void {
    this.scheduleFormType = 'Create';
    const scheduleForm = this.scheduleForm();
    scheduleForm.disableDelete = true;
    const startTime = new Date(arg.dateStr + ' ');
    startTime.setHours(7);
    const endTime = new Date(arg.dateStr + ' ');
    endTime.setDate(endTime.getDate() + 1);
    endTime.setHours(7);

    scheduleForm.scheduleFormGroup.patchValue({
      startTime: new Date(startTime),
      endTime: new Date(endTime),
    });
  }

  onDatesSelected(arg: any): void {
    this.scheduleFormType = 'Create';
    const scheduleForm = this.scheduleForm();
    scheduleForm.disableDelete = true;
    const startTime = new Date(arg.start + ' ');
    startTime.setHours(7);
    const endTime = new Date(arg.end + ' ');
    endTime.setDate(endTime.getDate() - 1);
    endTime.setHours(7);

    scheduleForm.scheduleFormGroup.patchValue({
      startTime: new Date(startTime),
      endTime: new Date(endTime),
    });
  }

  onEventSelected($event: any): void {
    this.selectedSchedule = $event.event;
    this.scheduleFormType = 'Edit';
    const todayClone = this.today;

    let scheduleTimezone = this.selectedSchedule.extendedProps.timezone;

    const scheduleForm = this.scheduleForm();
    if (this.selectedSchedule.end < todayClone) {
      this.scheduleForm().scheduleFormGroup.disable();
      scheduleForm.disableDelete = true;
    }

    if (this.selectedSchedule.start <= todayClone) {
      scheduleForm.disableDelete = true;
      scheduleForm.scheduleFormGroup.get('startTime').disable();
    }

    scheduleForm.scheduleFormGroup.patchValue({
      startTime: getDateInTimeZone(
        this.selectedSchedule.extendedProps.utcStart,
        scheduleTimezone
      ),
      endTime: getDateInTimeZone(
        this.selectedSchedule.extendedProps.utcEnd,
        scheduleTimezone
      ),
      scheduleTimeZone: scheduleForm.timeZones.find((tz) =>
        tz.value?.includes(scheduleTimezone)
      ),
      version: this.selectedSchedule.extendedProps.version,
    });
    if (this.selectedSchedule.extendedProps.isPrimary) {
      scheduleForm.scheduleFormGroup.get('secondary').disable();
      scheduleForm.scheduleFormGroup.patchValue({
        primary: scheduleForm.contacts.find(
          (c) =>
            c.firstName ===
              this.selectedSchedule.extendedProps.contact.firstName &&
            c.lastName ===
              this.selectedSchedule.extendedProps.contact.lastName &&
            c.id === this.selectedSchedule.extendedProps.contact.id
        ),
      });
    } else {
      scheduleForm.scheduleFormGroup.get('primary').disable();
      scheduleForm.scheduleFormGroup.patchValue({
        secondary: scheduleForm.contacts.find(
          (c) =>
            c.firstName ===
              this.selectedSchedule.extendedProps.contact.firstName &&
            c.lastName === this.selectedSchedule.extendedProps.contact.lastName
        ),
      });
    }
  }

  onScheduleFormSubmitted($event: any): void {
    let eventsCopy = [...this.events];
    let newEvent = {
      title:
        ($event.isPrimary ? 'Primary: ' : 'Secondary: ') +
        $event.contact.firstName +
        ' ' +
        $event.contact.lastName,
      start: $event.actualStartTime,
      end: $event.actualEndTime,
      ...$event,
    };

    delete newEvent.startTime;
    delete newEvent.endTime;

    eventsCopy.push(newEvent);
    this.events = eventsCopy;
    this.loadCalendarEvents();
  }

  onScheduleDeleted($event: any): void {
    let newEvents = this.events.filter((e) => e.id !== $event);

    this.events = newEvents;
    this.calendarOptions.events = this.events;
  }

  onScheduleImported(): void {
    this.loadCalendarEvents();
  }

  onScheduleExport(): void {
    this.exportSchedulesToCsv();
  }

  exportSchedulesToCsv(): void {
    let emptyScheduleData = {
      'User adAccountName': '',
      'Primary or Secondary': '',
      'Start Time': new Date(),
      'End Time': new Date(),
      'Time Zone': '',
    };
    let exportScheduleData = this.events
      .map((e) => ({
        'User adAccountName': e.contact.adAccountName,
        'Primary or Secondary': e.isPrimary ? 'P' : 'S',
        'Start Time': e.start,
        'End Time': e.end,
        'Time Zone': e.timezone,
      }))
      .sort((a, b) => sortByProperty(a, b, 'Start Time'))
      .filter((s) => s['Start Time'] > this.today);

    const delimiter = ',';
    const keys = Object.keys(exportScheduleData[0] || emptyScheduleData);
    const csvContent =
      keys.join(delimiter) +
      '\n' +
      exportScheduleData
        .map((row) => {
          return keys
            .map((k) => {
              let cell = row[k] === null || row[k] === undefined ? '' : row[k];
              cell =
                cell instanceof Date
                  ? moment(cell).format('YYYY-MM-DDTHH:mm:ss')
                  : cell.toString().replace(/"/g, '""');
              if (cell.search(/("|,|\n)/g) >= 0) {
                cell = `"${cell}"`;
              }
              return cell;
            })
            .join(delimiter);
        })
        .join('\n');

    const blob = new Blob([csvContent], { type: 'csv; charset=utf-8;' });
    const link = document.createElement('a');
    const downloadURL = window.URL.createObjectURL(blob);
    link.href = downloadURL;
    link.download = this.contactList.name + '/ContactList/Schedules.csv';
    link.click();
  }

  dataViewKeyUpFilter($event: KeyboardEvent): void {
    this.dataView().filter((<HTMLInputElement>$event.target).value);
  }
}
