import { Component, Input, OnInit, inject, input, output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { SelectItem } from 'primeng/api';
import { BaseComponent } from 'src/app/core/components/base.component';
import { DeviceSize } from 'src/app/core/enums/deviceSize.enum';
import { DirtyStatus } from 'src/app/model/common/dirty-status';
import { Availability } from 'src/app/model/contacts/availability';
import { Contact } from 'src/app/model/contacts/contact';
import { LogAndMessageService } from 'src/app/services/log-and-message.service';
import { isNullOrUndefined } from 'src/app/utils/utils';

export type PhoneFieldName = 'bm' | 'bp' | 'pm' | 'home' | 'other';

@Component({
  selector: 'app-contact-information-form',
  templateUrl: './contact-information-form.component.html',
  styleUrls: ['./contact-information-form.component.scss'],
  standalone: false,
})
export class ContactInformationFormComponent
  extends BaseComponent
  implements OnInit
{
  private _logAndMessage = inject(LogAndMessageService);

  DirtyStatus = DirtyStatus;
  DeviceSize = DeviceSize;
  readonly contact = input<Contact>(undefined);
  readonly form = input<UntypedFormGroup>(undefined);
  readonly availableCompanies = input<any[]>(undefined);
  readonly availableCountries = input<any[]>(undefined);
  readonly availableJobRoles = input<any[]>(undefined);
  // TODO: Skipped for migration because:
  //  This input overrides a field from a superclass, while the superclass field
  //  is not migrated.
  @Input() validationMessages: any;
  // TODO: Skipped for migration because:
  //  This input overrides a field from a superclass, while the superclass field
  //  is not migrated.
  @Input() validationParams: any;
  readonly modifyButtonLabel = input<string>(undefined);
  readonly modifyButtonIcon = input<string>(undefined);
  readonly selectedAvailability = input<Availability>(undefined);
  readonly showWhenUnavailable = input<boolean>(undefined);
  readonly canViewPII = input<boolean>(undefined);
  private lStartTime: Date;
  // TODO: Skipped for migration because:
  //  Accessor inputs cannot be migrated as they are too complex.
  @Input() set startTime(date: Date) {
    this.lStartTime = date;
    if (date) {
      this.disableStart = date < this.today;
    } else {
      this.disableStart = false;
    }
  }

  get startTime() {
    return this.lStartTime;
  }
  // TODO: Skipped for migration because:
  //  Your application code writes to the input. This prevents migration.
  @Input() endTime: Date;
  readonly filteredContacts = input<SelectItem[]>(undefined);
  readonly availableContactMethods = input<SelectItem[]>(undefined);
  readonly writeAccess = input(false);
  readonly goToContactEvent = output<any>();
  readonly modifyAvailabilityEvent = output<any>();
  readonly selectAvailabiityEvent = output<any>();
  readonly clearAvailabilityEvent = output();
  readonly updateAvailabilityEvent = output<any>();
  readonly deleteAvailabilityEvent = output<any>();
  readonly supervisorSearchEvent = output<any>();
  readonly selectSupervisorEvent = output<any>();
  readonly whenUnavailableSearchEvent = output<any>();
  readonly selectWhenUnavailableEvent = output<any>();
  readonly formPristineEvent = output();

  startCollapsed = false;
  minDate = new Date();
  today = new Date();
  defaultStartDate = new Date();
  defaultEndDate = new Date();
  disableStart = false;
  AvailabilityPurpose = 'Add';
  phoneMasks: Map<PhoneFieldName, string> = new Map();
  maskButtonVisible: Map<PhoneFieldName, boolean> = new Map();

  constructor() {
    super();
    this.phoneMasks.set('bp', '+1-999-999-9999');
    this.phoneMasks.set('bm', '+1-999-999-9999');
    this.phoneMasks.set('pm', '+1-999-999-9999');
    this.phoneMasks.set('home', '+1-999-999-9999');
    this.phoneMasks.set('other', '+1-999-999-9999');
    this.maskButtonVisible.set('bp', false);
    this.maskButtonVisible.set('bm', false);
    this.maskButtonVisible.set('pm', false);
    this.maskButtonVisible.set('home', false);
    this.maskButtonVisible.set('other', false);
  }

  ngOnInit() {
    if (this.ifDeviceMatches([DeviceSize.XS, DeviceSize.SM])) {
      this.startCollapsed = true;
    }

    let today = new Date();

    this.defaultStartDate = new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDay(),
      15,
      30
    );
    this.defaultEndDate = new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDay(),
      7,
      0
    );
  }

  isShown: boolean = false;

  openAvailability() {
    if (this.isShown == false) {
      this.isShown = true;
    } else if (this.isShown == true) {
      this.isShown = false;
    }
    this.AvailabilityPurpose = 'Add';
  }

  modifyAvailability() {
    if (this.validateDates()) {
      this.modifyAvailabilityEvent.emit({
        startTime: this.startTime,
        endTime: this.endTime,
        selected: this.selectedAvailability(),
      });
      this.clearAvailability();
    }
  }

  validateDates(): boolean {
    let retVal = true;

    this.form().updateValueAndValidity();
    if (!this.form().valid) {
      this._logAndMessage.translateToErrorMessage({
        headerKey: 'CONTACT.MESSAGES.HEADERS.UNFILLED_FORM_FIELDS',
        bodyKey: 'CONTACT.MESSAGES.WARN.UNFILLED_FORM_FIELDS',
      });
      return false;
    }

    if (!this.endTime || !this.startTime || this.endTime <= this.startTime) {
      this._logAndMessage.translateToErrorMessage({
        headerKey: 'CONTACT.MESSAGES.HEADERS.INVALID_DATES',
        bodyKey: 'CONTACT.MESSAGES.WARN.INVALID_DATES_MSG',
      });
      retVal = false;
    }

    this.contact().availabilities.forEach((a) => {
      const startD = new Date(a.start);
      const endD = new Date(a.end);
      const selectedAvailability = this.selectedAvailability();
      if (
        (!selectedAvailability ||
          (selectedAvailability && selectedAvailability.id !== a.id)) &&
        ((this.startTime >= startD && this.startTime <= endD) ||
          (this.endTime <= endD && this.endTime >= startD))
      ) {
        this._logAndMessage.translateToErrorMessage({
          headerKey: 'CONTACT.MESSAGES.HEADERS.INVALID_DATES',
          bodyKey: 'CONTACT.MESSAGES.WARN.OVERLAPPING_DATES',
        });
        retVal = false;
      }
    });

    return retVal;
  }

  clearAvailability() {
    this.startTime = null;
    this.endTime = null;
    this.disableStart = false;
    this.clearAvailabilityEvent.emit();
  }

  canBeModified(avail) {
    return new Date(avail.end) > this.today;
  }

  disabledForContact(): boolean {
    const contact = this.contact();
    return !isNullOrUndefined(contact) && !isNullOrUndefined(contact.id);
  }

  setMask(country: 'US' | 'MX', phoneField: PhoneFieldName) {
    // Note: If these masks are ever changed in the future, make sure to update the
    // regex validator in contact-builder#buildForm to match
    if (country === 'US') {
      this.phoneMasks.set(phoneField, '+1-999-999-9999');
    } else if (country === 'MX') {
      this.phoneMasks.set(phoneField, '+52-99-9999-9999');
    }
  }

  showMaskButtons(phoneField: PhoneFieldName) {
    this.maskButtonVisible.set(phoneField, true);
  }

  setMaskBasedOnFieldValue(phoneField: PhoneFieldName, value: string) {
    if (!value) {
      // Do nothing if there's no value
      return;
    }

    if (value.includes('+52')) {
      this.setMask('MX', phoneField);
    } else {
      this.setMask('US', phoneField);
    }
  }

  setMasksBasedOnFieldValues() {
    const contact = this.contact();
    this.setMaskBasedOnFieldValue('bm', contact.businessMobile);
    this.setMaskBasedOnFieldValue('bp', contact.businessPhone);
    this.setMaskBasedOnFieldValue('pm', contact.personalMobile);
    this.setMaskBasedOnFieldValue('home', contact.home);
    this.setMaskBasedOnFieldValue('other', contact.other);

    // Re-patch values in case they got removed when changing mask
    setTimeout(() => {
      this.form().patchValue({
        businessPhone: this.contact().businessPhone,
        businessMobile: this.contact().businessMobile,
        personalMobile: this.contact().personalMobile,
        home: this.contact().home,
        other: this.contact().other,
      });

      this.form().markAsPristine();
      this.formPristineEvent.emit();
    }, 50);
  }
}
