import { ChangeDetectorRef, Component, OnDestroy, OnInit, inject } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmationService, SelectItem } from 'primeng/api';
import { forkJoin, Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { VIEW_AUDIT } from 'src/app/constants/action-constants';
import {
  EXTERNAL_LIST_DETAIL_CONTAINER,
  EXTERNAL_LIST_SEARCH_CONTAINER,
} from 'src/app/constants/common.constants';
import {
  EXT_CONTACT_LIST_TAB_PREFIX,
  NEW_EXT_CONTACT_LIST,
} from 'src/app/constants/contact-constants';
import { DetailsContainer } from 'src/app/core/containers/details-container';
import { DeviceSize } from 'src/app/core/enums/deviceSize.enum';
import {
  buildAuditHistory,
  getDirtyValues,
} from 'src/app/core/functions/common-functions';
import { TabService } from 'src/app/core/services/tab.service';
import { BreadCrumb } from 'src/app/model/common/bread-crumb';
import { Column } from 'src/app/model/common/column';
import { DirtyStatus } from 'src/app/model/common/dirty-status';
import { ExternalContactList } from 'src/app/model/contacts/external-contact-list';
import { AuthApiService } from 'src/app/services/auth-api.service';
import { BreadCrumbBuilderService } from 'src/app/services/breadcrumb-builder.service';
import { ContactApiService } from 'src/app/services/contact-api.service';
import { ContactListApiService } from 'src/app/services/contact-list-api.service';
import { ContactListBuilderService } from 'src/app/services/contact-list-builder.service';
import { DeviceService } from 'src/app/services/device.service';
import { ExternalContactListTableService } from 'src/app/services/external-contact-list-table.service';
import { FacilityApiService } from 'src/app/services/facility-api.service';
import { FacilityTableService } from 'src/app/services/facility-table.service';
import { LoadingService } from 'src/app/services/loading.service';
import { LocationApiService } from 'src/app/services/location-api.service';
import { LogAndMessageService } from 'src/app/services/log-and-message.service';
import { getExternalContactListMemberDefinition } from '../../definitions/external-contact-list-member-table-definition';
import { isNullOrUndefined } from 'src/app/utils/utils';
import { CLAIMS } from 'src/app/constants/auth-constants';

@Component({
    selector: 'app-external-contact-list-details-container',
    templateUrl: './external-contact-list-details-container.component.html',
    styleUrls: ['./external-contact-list-details-container.component.scss'],
    standalone: false
})
export class ExternalContactListDetailsContainerComponent
  extends DetailsContainer
  implements OnInit, OnDestroy
{
  private _contactListTableService = inject(ExternalContactListTableService);
  private _contactListApi = inject(ContactListApiService);
  private _contactListBuilder = inject(ContactListBuilderService);
  private _fb = inject(UntypedFormBuilder);
  private _logAndMessage = inject(LogAndMessageService);
  protected _translateService: TranslateService;
  protected _deviceService: DeviceService;
  protected _confirmationService: ConfirmationService;
  protected _locationApi: LocationApiService;
  protected _facilityApi: FacilityApiService;
  protected _facilityTableService: FacilityTableService;
  protected _contactApi: ContactApiService;
  private _breadCrumbService = inject(BreadCrumbBuilderService);
  private _cdRef = inject(ChangeDetectorRef);
  private _loader = inject(LoadingService);
  private _authApi = inject(AuthApiService);

  DirtyStatus = DirtyStatus;
  DeviceSize = DeviceSize;
  contactList: ExternalContactList;
  loaded = false;
  saving = false;
  backToSearchResult: BreadCrumb;
  listBreadCrumb: BreadCrumb;

  isEditting = false;
  isEdittingMember = false;
  form: UntypedFormGroup;
  members: any[];
  memberColumns: Column[];
  displayDialog = false;
  externalContactCreateForm: UntypedFormGroup;
  canCreate = false;
  canEdit = false;
  canDelete = false;
  actions: SelectItem[];
  selectedAction: any = null;
  breadCrumbSub: Subscription;
  availableBusinessUnits: SelectItem[];

  constructor() {
    const _translateService = inject(TranslateService);
    const _deviceService = inject(DeviceService);
    const _confirmationService = inject(ConfirmationService);
    const _locationApi = inject(LocationApiService);
    const _facilityApi = inject(FacilityApiService);
    const _facilityTableService = inject(FacilityTableService);
    const _contactApi = inject(ContactApiService);

    super(
      _translateService,
      _deviceService,
      _confirmationService,
      _locationApi,
      _contactApi,
      _facilityTableService,
      _facilityApi
    );
    this._translateService = _translateService;
    this._deviceService = _deviceService;
    this._confirmationService = _confirmationService;
    this._locationApi = _locationApi;
    this._facilityApi = _facilityApi;
    this._facilityTableService = _facilityTableService;
    this._contactApi = _contactApi;

  }

  ngOnInit() {
    this.memberColumns = getExternalContactListMemberDefinition();
    this.setupTabClosingSubscription();
    this.setupBreadCrumbSub();
    this.externalContactCreateForm = this._fb.group({
      id: null,
      name: [null, Validators.required],
      description: null,
      phone: [null, Validators.required],
    });

    if (this._loader.isLoaded()) {
      this.initialize();
    } else {
      this._loader.loadingFinishedEvent.subscribe((event) => {
        this.initialize();
      });
    }
  }

  ngOnDestroy(): void {
    if (this.tabClosingSub) {
      this.tabClosingSub.unsubscribe();
    }

    if (this.valueSub) {
      this.valueSub.unsubscribe();
    }

    if (this.breadCrumbSub) {
      this.breadCrumbSub.unsubscribe();
    }
  }

  setupBreadCrumbSub(): void {
    this.breadCrumbSub =
      this._breadCrumbService.breadCrumbSelectedEvent.subscribe(
        (breadCrumb) => {
          this._breadCrumbService.removeBreadCrumb(this.listBreadCrumb);
          const tab = TabService.getInstance().buildNewTab(
            EXTERNAL_LIST_SEARCH_CONTAINER,
            true
          );
          TabService.getInstance().setMobileTab(tab);
        }
      );
  }

  initialize() {
    this.availableBusinessUnits = this._authApi
      .getUserBusinessUnits()
      .map((bu) => {
        return { label: bu.name, value: bu.id };
      });

    const id = this.id;
    this.contactList = this._contactListTableService.getSelected();
    if (
      !isNullOrUndefined(this.contactList) &&
      !isNullOrUndefined(this.contactList.id)
    ) {
      this.setupScreen();
    } else if (id) {
      // use network query to find contact list by id.
      this._contactListApi
        .getExternalContactList(id)
        .pipe(take(1))
        .subscribe(
          ({ data }) => {
            const clone = { ...data };
            this.contactList =
              this._contactListBuilder.buildExternalContactList(
                clone.getExternalContactList
              );
            this.doCheckComplete = false;
            this.setupScreen();
          },
          (error) => {
            console.log(error);
            this._logAndMessage.translateToErrorMessage({
              headerKey: 'COMMON.MESSAGES.HEADERS.RETRIEVE_ERROR',
              bodyKey: 'COMMON.MESSAGES.ERROR.RETRIEVE_ERROR_MESSAGE',
            });
          }
        );
      this.updateActionsList();
    } else {
      this.contactList = this._contactListTableService.getSelected();

      if (!this.contactList) {
        this._logAndMessage.errorLogOnly('Contact List was null');
        if (this._deviceService.isMobile()) {
          const tab = TabService.getInstance().buildNewTab(
            EXTERNAL_LIST_SEARCH_CONTAINER,
            true
          );
          TabService.getInstance().setMobileTab(tab);
        } else {
          const index = TabService.getInstance().getActiveIndex();
          TabService.getInstance().closeTab(index);
        }
      } else {
        if (!this.contactList.id) {
          this.isEditting = true;
        }
        this.setupScreen();
      }
    }
  }

  setupScreen() {
    if (this._deviceService.isMobile()) {
      this._translateService
        .get('CONTACT.SCREEN.EXTERNAL_CONTACT_LIST')
        .subscribe((label) => {
          this._breadCrumbService.resetAndAddBreadCrumb(
            new BreadCrumb(label, null, true)
          );
          if (this.contactList) {
            this.listBreadCrumb = new BreadCrumb(
              this.contactList.name,
              null,
              false
            );
            this._breadCrumbService.addBreadCrumb(this.listBreadCrumb);
          }
        });
    } else {
      if (isNullOrUndefined(this.contactList.id)) {
        TabService.getInstance().updateActiveTabLabel(NEW_EXT_CONTACT_LIST);
      } else {
        TabService.getInstance().updateActiveTabLabel(
          EXT_CONTACT_LIST_TAB_PREFIX + this.contactList.name
        );
      }
    }

    this.canCreate = this._authApi.doesUserHaveAllClaimsFromList([
      CLAIMS.CONTACTS.EXTERNAL_LISTS.CREATE_EXTERNAL_LISTS,
    ]);
    this.canDelete = this._authApi.doesUserHaveAllClaimsFromList([
      CLAIMS.CONTACTS.EXTERNAL_LISTS.DELETE_EXTERNAL_LISTS,
    ]);
    this.canEdit = this._authApi.doesUserHaveAllClaimsFromList([
      CLAIMS.CONTACTS.EXTERNAL_LISTS.EDIT_EXTERNAL_LISTS,
    ]);
    this._cdRef.markForCheck();
    this.buildForm();
    this.updateForm();
    this.loading = false;
  }

  buildForm() {
    this.form = this._fb.group({
      name: [null, Validators.required],
      notes: null,
      businessUnits: [null, Validators.required],
      members: this._fb.array([]),
    });

    this.valueSub = this.form.valueChanges.subscribe((changes) => {
      if (this.form.dirty) {
        this.changesEvent.emit({
          isDirty: true,
          index: this.index(),
          id: this.id,
        });
      }
    });
  }

  updateForm() {
    if (this.contactList) {
      this.form.patchValue({
        name: this.contactList.name,
        notes: this.contactList.notes,
        businessUnits: this.contactList.businessUnits.map((bu) => bu.id),
      });
      this.members = [...this.contactList.members];
    }
    if (!this.isEditting) {
      this.form.disable();
    }
  }

  editMember() {
    this.externalContactCreateForm.enable();
    this.isEdittingMember = true;
  }

  addMember() {
    this.isEdittingMember = true;
    this.externalContactCreateForm.patchValue({
      id: null,
      name: null,
      description: null,
      phone: null,
    });
    this.externalContactCreateForm.enable();
    this.displayDialog = true;
  }

  modifyMember($event) {
    if ($event) {
      this.externalContactCreateForm.patchValue({
        id: $event.data.id,
        name: $event.data.name,
        description: $event.data.description,
        phone: $event.data.phone,
      });
      this.externalContactCreateForm.disable();
      this.displayDialog = true;
    }
  }

  updateMember() {
    const raw = this.externalContactCreateForm.getRawValue();
    const array = this.contactList.members.filter((m) => m.id === raw.id);
    if (array.length > 0) {
      const member = array[0];
      member.dirtyStatus = DirtyStatus.UPDATED;
      member.name = raw.name;
      member.description = raw.description;
      member.phone = raw.phone;
    } else {
      this.contactList.members.push({
        id: null,
        dirtyStatus: DirtyStatus.NEW,
        name: raw.name,
        description: raw.description,
        phone: raw.phone,
        ExternalContactListId: this.contactList.id,
        order: this.members.length + 1,
      });
    }

    this.saveExternalContactList();
  }

  deleteMember() {
    // Remove element from contactListMembers and save the list
    const raw = this.externalContactCreateForm.getRawValue();
    const array = this.contactList.members.filter((m) => m.id === raw.id);
    if (array.length > 0) {
      const member = array[0];
      member.dirtyStatus = DirtyStatus.DELETED;
      this.contactList.members = [
        ...this.contactList.members.filter((m) => m.id !== raw.id),
      ];
    }

    this.saveExternalContactList();
  }

  setEditting() {
    this.isEditting = true;
    this.form.enable();
  }

  saveExternalContactList() {
    if (!this.canEdit && !this.canCreate) {
      return;
    }
    const dv = getDirtyValues(this.form);
    this.saving = true;
    this.isEditting = false;
    const updateStatement =
      this._contactListBuilder.buildExternalContactListUpdateStatement(
        this.contactList,
        dv
      );
    if (this.contactList.id) {
      this.updateContactList(updateStatement);
    } else {
      this.createContactList(updateStatement);
    }
  }

  updateContactList(updateStatement) {
    if (!this.canEdit) {
      return;
    }
    this._contactListApi
      .updateExternalContactList(
        this.contactList.id,
        this.contactList,
        updateStatement
      )
      .pipe(take(1))
      .subscribe(
        ({ data }) => {
          const clone = { ...data };
          this.handleContactListSave(clone.updateExternalContactList);
        },
        (error) => {
          console.log(error);
          this.saving = false;
        }
      );
  }

  createContactList(updateStatement) {
    if (!this.canCreate) {
      return;
    }
    this._contactListApi
      .createExternalContactList(this.contactList, updateStatement)
      .pipe(take(1))
      .subscribe(
        ({ data }) => {
          this.handleContactListSave(data.createExternalContactList);
        },
        (error) => {
          console.log(error);
          this.saving = false;
        },
        () => {
          this._contactListTableService.setSelected(this.contactList);
          if (!this._deviceService.isMobile()) {
            const oldTab = TabService.getInstance().getActiveTab();
            const tab = TabService.getInstance().buildNewTab(
              EXTERNAL_LIST_DETAIL_CONTAINER,
              true,
              null,
              this.contactList.id
            );
            TabService.getInstance().replaceTab(oldTab, tab);
          }
        }
      );
  }

  handleContactListSave(data) {
    this._logAndMessage.translateToSuccessMessage({
      bodyKey: 'COMMON.MESSAGES.SUCCESS.SAVED_SUCCESSFULLY',
      headerKey: 'COMMON.MESSAGES.HEADERS.SUBMIT',
    });
    this.contactList = this._contactListBuilder.buildExternalContactList(data);
    this.saving = false;
    this.isEditting = false;
    this.isEdittingMember = false;
    this.displayDialog = false;
    this.updateForm();
    this.form.disable();
    this.form.markAsPristine();
    this.updateActionsList();
    this._cdRef.markForCheck();
    this.changesEvent.emit({ isDirty: false, index: this.index(), id: this.id });
  }

  deleteExternalList() {
    if (!this.canDelete) {
      return;
    }
    const id = this.contactList.id;
    this.saving = true;
    this._contactListApi
      .deleteExternalContactList(id, this.contactList, {})
      .pipe(take(1))
      .subscribe(
        ({ data }) => {
          this._logAndMessage.translateToSuccessMessage({
            bodyKey: 'COMMON.MESSAGES.SUCCESS.SAVED_SUCCESSFULLY',
            headerKey: 'COMMON.MESSAGES.HEADERS.SUBMIT',
          });
          this._contactListTableService.clearResults();
          this.saving = false;
        },
        (error) => {
          console.log(error);
          this.saving = false;
        },
        () => {
          if (this._deviceService.isMobile()) {
            const tab = TabService.getInstance().buildNewTab(
              EXTERNAL_LIST_SEARCH_CONTAINER,
              true
            );
            TabService.getInstance().setMobileTab(tab);
          } else {
            const index = TabService.getInstance().getActiveIndex();
            TabService.getInstance().closeTab(index);
          }
        }
      );
  }

  resetEdittingMember() {
    this.isEdittingMember = false;
  }

  updateActionsList() {
    const selectAction$ = this._translateService.get(
      'COMMON.LABEL.SELECT_ACTION'
    );
    const viewAudit$ = this._translateService.get(
      'COMMON.LABEL.VIEW_AUDIT_INFO'
    );

    let select,
      audit = null;
    forkJoin([selectAction$, viewAudit$])
      .pipe(take(1))
      .subscribe((messages) => {
        select = messages[0];
        audit = messages[1];

        this.actions = [
          { label: select, value: null },
          { label: audit, value: VIEW_AUDIT },
        ];

        // default actions here
        this.actions = [...this.actions];
      });
  }

  actionEvent($event, object?: any) {
    if ($event.value) {
      this.selectedAction = null;
      if ($event.value) {
        if ($event.value === VIEW_AUDIT) {
          this.auditLoading = true;
          this._contactListApi
            .findExternalContactListHistory(this.contactList.id)
            .pipe(take(1))
            .subscribe(
              ({ data }) => {
                const clone = { ...data };
                this.history = [
                  ...buildAuditHistory(clone.getExternalContactListHistory),
                ];
                this._cdRef.markForCheck();
              },
              (error) => {
                this.auditLoading = false;
              },
              () => {
                this.auditLoading = false;
                this.displayAuditDialog = true;
              }
            );
        }
      }

      if (!isNullOrUndefined(object)) {
        object.clear(null);
      }
    }
    this._cdRef.markForCheck();
  }

  showActionsDropdown(): boolean {
    return (
      !isNullOrUndefined(this.contactList) &&
      !isNullOrUndefined(this.contactList.id) &&
      !this._deviceService.isMobile()
    );
  }

  fixMask(field) {
    const fieldValue = this.form.controls[field];
    if (fieldValue) {
      const value: string = fieldValue.value;
      if (value.indexOf('_') > -1) {
        this.form.controls[field].setValue('');
        this.form.updateValueAndValidity();
      }
    }
  }
}
