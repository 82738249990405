import { Component, OnInit, Input, inject, input, output } from '@angular/core';
import { ContactListMember } from 'src/app/model/contacts/contact-list-member';
import { BaseComponent } from 'src/app/core/components/base.component';
import { ThemeService } from 'src/app/services/theme.service';

@Component({
    selector: 'app-contact-list-card',
    templateUrl: './contact-list-card.component.html',
    styleUrls: ['./contact-list-card.component.scss'],
    standalone: false
})
export class ContactListCardComponent extends BaseComponent implements OnInit {
  private _themeService = inject(ThemeService);

  private lIndex: number;
  // TODO: Skipped for migration because:
  //  Accessor inputs cannot be migrated as they are too complex.
  @Input() set index(i: number) {
    this.lIndex = i + 1;
  }
  get index(): number {
    return this.lIndex;
  }
  // TODO: Skipped for migration because:
  //  This input is used in a control flow expression (e.g. `@if` or `*ngIf`)
  //  and migrating would break narrowing currently.
  @Input() member: ContactListMember;
  readonly openProfileEvent = output<any>();
  readonly selectEvent = output<any>();

  readonly selected = input(false);

  ngOnInit() {}

  openProfile(id = null) {
    if (!id) {
      id = this.member.contact.id;
    }
    this.openProfileEvent.emit(id);
  }

  cardClassName() {
    const selectedTheme = this._themeService.getSelectedTheme();
    const isDark = selectedTheme === 'arya-green';
    const classAdjust = isDark ? '-dark' : '';

    // Check if contact is unavailable
    if (
      this.member.contact.isUnavailable === 'TRUE' ||
      this.member.contact.inactiveAdAccount
    ) {
      return (
        'ui-helper-clearfix contact-list-item contact-list-item-unavail' +
        classAdjust
      );
    }

    // Check if contact is currently scheduled
    else if (this.member.isScheduled) {
      return (
        'ui-helper-clearfix contact-list-item contact-list-item-scheduled' +
        classAdjust
      );
    } else {
      return (
        'ui-helper-clearfix contact-list-item contact-list-item-avail' +
        classAdjust
      );
    }
  }
}
