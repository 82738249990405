import { ChangeDetectorRef, Component, EventEmitter, OnDestroy, OnInit, viewChild, inject } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { LazyLoadEvent } from 'primeng/api';
import { forkJoin } from 'rxjs';
import { take } from 'rxjs/operators';
import { CONTACT_DETAIL_CONTAINER } from 'src/app/constants/common.constants';
import { SearchContainerComponent } from 'src/app/core/containers/search-container/search-container.component';
import { DeviceSize } from 'src/app/core/enums/deviceSize.enum';
import { TabService } from 'src/app/core/services/tab.service';
import { Contact } from 'src/app/model/contacts/contact';
import { AuthService } from 'src/app/services/auth.service';
import { DeviceService } from 'src/app/services/device.service';
import { LoadingService } from 'src/app/services/loading.service';
import { LogAndMessageService } from 'src/app/services/log-and-message.service';
import { ContactApiService } from '../../../services/contact-api.service';
import { AuthApiService } from 'src/app/services/auth-api.service';
import { ContactBuilderService } from '../../../services/contact-builder.service';
import { ContactTableService } from 'src/app/services/contact-table.service';
import { getContactTableColumns } from '../../definitions/contacts-table-definition';
import { BreadCrumbBuilderService } from 'src/app/services/breadcrumb-builder.service';
import { BreadCrumb } from 'src/app/model/common/bread-crumb';
import { BusinessUnitFilterComponent } from 'src/app/core/components/business-unit-filter/business-unit-filter.component';
import { CLAIMS } from 'src/app/constants/auth-constants';

/**
 * Main search component for the contact module.
 */
@Component({
    selector: 'app-contacts-search-container',
    templateUrl: './contacts-search-container.component.html',
    styleUrls: ['./contacts-search-container.component.scss'],
    standalone: false
})
export class ContactsSearchContainerComponent
  extends SearchContainerComponent<Contact>
  implements OnInit, OnDestroy
{
  private _translateService = inject(TranslateService);
  private _breadCrumbBuilderService = inject(BreadCrumbBuilderService);
  protected _deviceService: DeviceService;
  private _logger = inject(LogAndMessageService);
  private _contactApi = inject(ContactApiService);
  private _contactBuilder = inject(ContactBuilderService);
  private _router = inject(Router);
  private _contactTableService = inject(ContactTableService);
  private _authApi = inject(AuthApiService);
  protected _cdRef: ChangeDetectorRef;
  protected _auth: AuthService;
  protected _loader: LoadingService;

  readonly businessUnitFilter = viewChild<BusinessUnitFilterComponent>('buFilter');
  allContacts: any[];
  contactsLoading: boolean;
  availableCompanies: any[];
  contactsLoadingEvent = new EventEmitter<any>();
  scrollHeight = 'calc(100vh - 256px)';
  mobileFilterCollapsed = true;
  firstLoad = 1;

  constructor() {
    const _deviceService = inject(DeviceService);
    const _cdRef = inject(ChangeDetectorRef);
    const _auth = inject(AuthService);
    const _loader = inject(LoadingService);

    super(_deviceService, _loader, _auth, _cdRef);
    this._deviceService = _deviceService;
    this._cdRef = _cdRef;
    this._auth = _auth;
    this._loader = _loader;

  }

  ngOnInit() {
    this.setTableService(this._contactTableService);
    if (this._loader.isLoaded()) {
      this.initialize();
    } else {
      this._loader.loadingFinishedEvent.subscribe((event) => {
        this.initialize();
      });
    }
  }

  initialize() {
    if (this.ifDeviceMatches([DeviceSize.XS, DeviceSize.SM])) {
      this.scrollHeight = 'calc(100vh - 180px)';
    }
    // setup the bread crumb.
    this._translateService.get('CONTACT.SCREEN.CONTACT').subscribe((label) => {
      if (this._deviceService.isMobile()) {
        this._breadCrumbBuilderService.resetAndAddBreadCrumb(
          new BreadCrumb(label, null, false)
        );
      } else {
        TabService.getInstance().updateActiveTabLabel(label);
      }
      this.screenName = label;
    });

    // setup the select item lists and columns for the grid.
    const availableStatus = [];
    const activeStatus$ = this._translateService.get('COMMON.LABEL.ACTIVE');
    const inactiveStatus$ = this._translateService.get('COMMON.LABEL.INACTIVE');
    forkJoin([activeStatus$, inactiveStatus$])
      .pipe(take(1))
      .subscribe((messages) => {
        availableStatus.push({ label: '', value: null });
        availableStatus.push({ label: messages[0], value: messages[0] });
        availableStatus.push({ label: messages[1], value: messages[1] });
      });

    this._contactApi
      .loadAvailableCompanies()
      .pipe(take(1))
      .subscribe(({ data }) => {
        this.availableCompanies = [
          { label: '', value: null },
          ...data.getAvailableCompanies.map((c) => ({
            label: c.name,
            value: c.name,
          })),
        ];
        this.columns = [
          ...getContactTableColumns(
            this.availableCompanies,
            availableStatus,
            this._deviceService.isMobile()
          ),
        ];
        this.applyPreviousFilters();
        this.applyPreviousData();
        this._cdRef.markForCheck();
      });
  }

  ngOnDestroy(): void {
    // clean up.
  }

  /**
   * Method called when the user hits the clear button.
   */
  clearScreen() {
    this.lazyLoad({ lazy: {} });
    this.grid().resetTable();
    this._contactTableService.clearResults();
    this.elements = [];
  }

  /**
   * Method called when a row double click event is fired.  This navigates to the details page.
   * @param $event - Event from the double click event.
   */
  changeViewEvent($event) {
    this._contactTableService.setScreenEmbedded(false);
    const tab = TabService.getInstance().buildNewTab(
      CONTACT_DETAIL_CONTAINER,
      true,
      null,
      $event.data.id
    );
    if (this._deviceService.isMobile()) {
      TabService.getInstance().setMobileTab(tab);
    } else {
      TabService.getInstance().openTab(tab);
    }
  }

  private queryNetwork(req, $event, query) {
    req.page += 1;
    const sort = {};
    if ($event.lazy && $event.lazy.sortField) {
      sort[$event.lazy.sortField] =
        $event.lazy.sortOrder && $event.lazy.sortOrder === 1 ? 'DESC' : 'ASC';
      this._contactTableService.setLastSortField($event.lazy.sortField);
      this._contactTableService.setLastSortDirection($event.lazy.sortOrder);
    } else {
      sort['firstName'] = 'ASC';
      this._contactTableService.setLastSortField('firstName');
      this._contactTableService.setLastSortDirection(1);
    }

    const trueQuery = {};
    Object.keys(query).forEach((key) => {
      //if (key !== 'activeStatus' && key !== 'isUnavailable') {  // TODO: Add to query/sort
      if (key !== 'isUnavailable' && key !== 'businessUnits') {
        if (query[key]) {
          trueQuery[key] = query[key].trim();
        }
      }
    });
    trueQuery['businessUnits'] = query['businessUnits'];

    this._contactApi
      .queryForContacts(req.pageSize, req.page, trueQuery, sort)
      .pipe(take(1))
      .subscribe(
        ({ data }) => {
          const clone = Object.assign({}, data);
          // filter based on query
          this.totalRecords = clone.queryForContacts.totalRecords;
          this._contactTableService.setLastTotalRecords(this.totalRecords);
          this.allContacts = [
            ...clone.queryForContacts.items.map((i) =>
              this._contactBuilder.buildContact(i)
            ),
          ];
          this.elements = [...this.allContacts];
          this.loading = false;
          this._cdRef.markForCheck();
        },
        (error) => {
          console.log(error);
        }
      );
  }

  mobileFilter($event) {
    const newLazy: LazyLoadEvent = {
      filters: {
        ...this.grid().table().filters,
        fullName: $event.name
          ? {
              value: $event.name,
              matchMode: 'startsWith',
            }
          : null,
        supervisor: $event.supervisor
          ? {
              value: $event.supervisor,
              matchMode: 'startsWith',
            }
          : null,
      },
    };
    this.mobileFilterCollapsed = true;
    this._contactTableService.setLastLazyLoad(newLazy);
    this.lazyLoad({ lazy: this._contactTableService.getLastLazyLoad() });
  }

  handleLazyLoad(req, $event, filters) {
    let selectedBusinessUnits = [];
    const businessUnitFilter = this.businessUnitFilter();
    if (businessUnitFilter?.selectedBusinessUnits) {
      // Handle further refreshes & changes to business unit filter component
      selectedBusinessUnits = businessUnitFilter.selectedBusinessUnits;
    } else {
      // Handle initial page load (before the business unit filter subcomponent exists)
      selectedBusinessUnits = this._authApi
        .getUserBusinessUnits()
        .map((bu) => bu.id);
    }
    filters['businessUnits'] = selectedBusinessUnits;

    const query = this._contactBuilder.buildContactQuery(filters);
    this.queryNetwork(req, $event, query);
  }

  handleBusinessUnitChange($event) {
    this.lazyLoad({ lazy: this._contactTableService.getLastLazyLoad() });
  }

  canUserAddContact(): boolean {
    return this._authApi.doesUserHaveAllClaimsFromList([CLAIMS.CONTACTS.CONTACTS.CREATE_CONTACT]);
  }

  addNewUser(): void {
    this._contactTableService.setSelected(undefined);
    this._contactTableService.setScreenEmbedded(false);
    const tab = TabService.getInstance().buildNewTab(
      CONTACT_DETAIL_CONTAINER,
      true,
      null,
      'newContact'
    );
    TabService.getInstance().openTab(tab);
  }

  refresh(): void {
    this.lazyLoad({ lazy: this._contactTableService.getLastLazyLoad() });
  }
}
