<p-blockUI [blocked]="loading === true"></p-blockUI>
@if (ifDeviceMatches([DeviceSize.MD, DeviceSize.LG, DeviceSize.XL])) {
  <div class="ui-g ui-fluid" id="contact-action-buttons"
    >
    <div class="buttons">
      <div id="business-units-wrapper">
        <app-business-unit-filter (selectedBusinessUnitsChanged)="handleBusinessUnitChange($event)" #buFilter></app-business-unit-filter>
      </div>
      @if (canUserAddContact()) {
        <button pButton class="width-eighty" label="{{'COMMON.LABEL.BUTTONS.NEW' | translate}}"
        icon="fa fa-fw fa-plus" (click)="addNewUser()"></button>
      }
      <button pButton class="refresh-button width-twenty" icon="fa fa-fw fa-refresh" (click)="refresh()"></button>
      <button pButton label="{{'COMMON.LABEL.BUTTONS.CLEAR' | translate}}" icon="fa fa-fw fa-eraser"
      (click)="clearScreen()"></button>
    </div>
  </div>
}

@if (ifDeviceMatches([DeviceSize.XS, DeviceSize.SM])) {
  <p-panel [showHeader]="true" header="Filters" [toggleable]="true" [(collapsed)]="mobileFilterCollapsed"
    >
    <div class="ui-g ui-fluid">
      <div class="ui-g-12">
        <app-contact-mobile-filter (mobileFilterEvent)="mobileFilter($event)" [showMobileFilter]="true">
        </app-contact-mobile-filter>
      </div>
    </div>
  </p-panel>
}

<app-progress-spinner [enabled]="!elements"></app-progress-spinner>
<div [style.display]="!elements ? 'none' : ''">
  <app-general-grid [values]="elements" [columns]="columns" [loading]="loading" [deviceSize]="getDeviceSize()"
    [rows]="rows" [totalRecords]="totalRecords" [filterable]="true" [screenName]="screenName"
    [lazy]="true" (lazyLoad)="lazyLoad($event)" [includeExportCSV]="false"
    (changeViewEvent)="changeViewEvent($event)" [scrollHeight]="scrollHeight" [usePaginator]="true"
    [rowsPerPageOptions]="[50, 100, 200]" #grid>
  </app-general-grid>
</div>
