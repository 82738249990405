<p-blockUI [blocked]="saving === true"></p-blockUI>

@if (ifDeviceMatches([DeviceSize.MD, DeviceSize.LG, DeviceSize.XL])) {
  <p-panel
  [showHeader]="false"
  styleClass="hidden-panel"
>
  <div class="no-padding display-flex table-container">
    <div
      class="contact-list-table"
      [ngClass]="{ long: nonOrderableMembers.length < 1 }"
    >
      <div class="contact-list-header">
        <div>
          <app-progress-spinner [enabled]="saving"></app-progress-spinner>
        </div>

        <div class="row">
          <div class="row-item">
            <span class="label">{{ "CONTACT.LABEL.NAME" | translate }}:</span>
            <div class="input">
              <input
                type="text"
                pInputText
                [(ngModel)]="name"
                [disabled]="!this.canEdit"
                (change)="listChange($event)"
              />
            </div>
          </div>
          <div class="row-item">
            <span class="label contact-list-input"
              >{{ "CONTACT.LABEL.MEMBER_CODES" | translate }}:
            </span>
            <div>{{ this.codesToDisplay }}</div>
          </div>
          <div class="row-item">
            <span class="label small-margin-right"
              >{{ "COMMON.LABEL.BUSINESS_UNITS" | translate }}:<span
                class="required"
                >*</span
              ></span
            >
            <span class="row-input">
              <p-multiSelect
                [options]="availableBusinessUnits"
                [(ngModel)]="selectedBusinessUnits"
                size="4"
                [style]="{ width: 'inherit' }"
                (onChange)="handleBusinessUnitChange($event)"
                [disabled]="!this.canEdit"
              >
              </p-multiSelect>
            </span>
          </div>
          <div class="manage-button">
            <button
              pButton
              label="{{ 'CONTACT.LABEL.MANAGE_MEMBER_CODES' | translate }}"
              icon="fa fa-fw fa-tags"
              [disabled]="
                !(this.canAddMemberCodes || this.canRemoveMemberCodes)
              "
              (click)="manageMemberCodes()"
            ></button>
          </div>
          @if (this.canEdit || this.canEditDirectReports || this.canEditOwn || this.canEditUpdater) {
            <div class="button">
              <button
                pButton
                label="{{ 'COMMON.LABEL.BUTTONS.ADD' | translate }}"
                icon="fa fa-fw fa-plus"
                (click)="addContact()"
                [disabled]="saving"
              ></button>
            </div>
            <div>
              <button
                pButton
                label="{{ 'COMMON.LABEL.BUTTONS.DELETE' | translate }}"
                icon="fa fa-fw fa-trash"
                (click)="deleteContact()"
                [disabled]="!selectedEmployees || saving"
              ></button>
            </div>
          }
        </div>
      </div>

      <div>
        <app-progress-spinner [enabled]="loading()"></app-progress-spinner>
      </div>
      <div class="no-padding list-card">
        <p-orderList
          [value]="orderableMembers"
          [styleClass]="orderListStyle"
          [listStyle]="{ height: 'calc(100vh - 400px)' }"
          (onReorder)="contactListReorder($event)"
          [(selection)]="selectedEmployees"
          [dragdrop]="dragDropEnabled"
          controlsPosition="left"
          (onSelectionChange)="onSelectionChange($event)"
        >
          <ng-template let-m pTemplate="item" let-index="index">
            <app-contact-list-card
              [member]="m"
              [index]="index"
              [deviceSize]="getDeviceSize()"
              (openProfileEvent)="openEmployeeProfile($event)"
            >
            </app-contact-list-card>
          </ng-template>
        </p-orderList>
      </div>
    </div>
  </div>
  </p-panel>
}

@if (ifDeviceMatches([DeviceSize.XS, DeviceSize.SM])) {
  <div>
    <div class="no-padding">
      <div class="no-padding">
        <span class="label no-padding"
          >{{ "CONTACT.LABEL.NAME" | translate }}:</span
        >
        <div class="no-padding">
          <input
            type="text"
            pInputText
            [(ngModel)]="name"
            (change)="listChange($event)"
          />
        </div>
      </div>
    </div>

    <div class="no-padding" id="mobile-member-codes">
      <div class="row-item member-codes">
        <span class="label contact-list-input"
          >{{ "CONTACT.LABEL.MEMBER_CODES" | translate }}:
        </span>
        <div>{{ this.codesToDisplay }}</div>
      </div>
    </div>
  </div>
}
<br />

@if (ifDeviceMatches([DeviceSize.XS, DeviceSize.SM])) {
  <p-panel
    [showHeader]="true"
    [toggleable]="true"
    toggler="header"
    [(collapsed)]="empCollapsed"
    header="Employees"
    styleClass="hidden-panel"
  >
    <div class="no-padding">
      <p-scrollPanel styleClass="contact-list-scroll-panel">
        <div class="no-padding">
          <p-orderList
            [value]="orderableMembers"
            [styleClass]="orderListStyle"
            [listStyle]="{ height: 'calc(100vh - 400px)' }"
            (onReorder)="contactListReorder($event)"
            [(selection)]="selectedEmployees"
            [dragdrop]="dragDropEnabled"
            (onSelectionChange)="onSelectionChange($event)"
          >
            <ng-template let-m pTemplate="item" let-index="index">
              <app-contact-list-card
                [member]="m"
                [index]="index"
                [deviceSize]="getDeviceSize()"
                (openProfileEvent)="openEmployeeProfile($event)"
              >
              </app-contact-list-card>
            </ng-template>
          </p-orderList>
        </div>
      </p-scrollPanel>
    </div>
  </p-panel>
}

<p-dialog
  header="Add Contacts To List"
  [(visible)]="displayDialog"
  showEffect="fade"
  [modal]="true"
  [transitionOptions]="'0ms'"
  [style]="{ width: '500px' }"
  appendTo="body"
>
  <form [formGroup]="addContactToListForm">
    <div id="add-contact-create-form" class="add-contact-form">
      <div class="row">
        <span class="label">{{ "CONTACT.LABEL.CONTACT" | translate }}:</span>
        <span class="input">
          <p-autoComplete
            [suggestions]="filteredContacts"
            (completeMethod)="contactSearch($event)"
            formControlName="contact"
            [dropdown]="true"
            field="label"
            appendTo="body"
          ></p-autoComplete>
        </span>
      </div>
      <div class="button">
        <button
          pButton
          label="{{ 'COMMON.LABEL.BUTTONS.ADD' | translate }}"
          icon="fa fa-fw fa-save"
          (click)="addContactToList()"
          [disabled]="!this.addContactToListForm.valid"
        ></button>
      </div>
    </div>
  </form>
</p-dialog>

<app-member-codes-form
  [isVisible]="displayMemberCodesDialog"
  [contactList]="contactList"
  formTitle="Manage Member Codes - {{ contactList.name }}"
  (memberCodesHidden)="onMemberCodesHidden()"
  (memberCodesUpdated)="onMemberCodesUpdated($event)"
  #memberCodeForm
></app-member-codes-form>
