@if (isFacilitiesEmpty()) {
  <div>
    <span class="label">{{
      "CONTACT.LABEL.NOT_ASSOCIATED_WITH_FACILITY" | translate
    }}</span>
  </div>
}
@for (f of facilities(); track f) {
  <div>
    <p-panel [showHeader]="false">
      @if (!isMobile()) {
        <div class="row-container">
          <div id="info-container">
            <div id="location-info">
              <div class="info-set">
                <span class="label">{{ "LOCATION.LABEL.STATE" | translate }}:</span>
                <span>{{ f.state }}</span>
                <span class="label"
                  >{{ "LOCATION.LABEL.COUNTY" | translate }}:</span
                  >
                  <span>{{ f.county }}</span>
                  <span class="label"
                    >{{ "LOCATION.LABEL.TOWNSHIP" | translate }}:</span
                    >
                    <span>{{ f.township }}</span>
                    <span class="label">{{ "LOCATION.LABEL.AOR" | translate }}:</span>
                    <span>{{ f.aor }}</span>
                  </div>
                </div>
                <div class="break"></div>
                <div id="facility-info">
                  <div class="info-set">
                    <span class="label"
                      >{{ "LOCATION.LABEL.FACILITY_NAME" | translate }}:</span
                      >
                      <span>{{ f.facilityName }}</span>
                      <span class="label"
                        >{{ "LOCATION.LABEL.FACILITY_UID" | translate }}:</span
                        >
                        <span>{{ f.facilityUid }}</span>
                        <span class="label"
                          >{{ "LOCATION.LABEL.FACILITY_TYPE" | translate }}:</span
                          >
                          <span>{{ f.FacilityType.name }}</span>
                          <span class="label"
                            >{{ "LOCATION.LABEL.SCADA_ID" | translate }}:</span
                            >
                            <span>{{ f.scadaId }}</span>
                          </div>
                        </div>
                      </div>
                      <div id="buttons">
                        @if (f && f.ContactListId) {
                          <div>
                            <button
                              pButton
                              label="{{ 'COMMON.LABEL.BUTTONS.OPEN_LIST' | translate }}"
                              icon="fa fa-fw fa-location-arrow"
                              (click)="this.openContactListEvent.emit(f)"
                            ></button>
                          </div>
                        }
                        @if (f && f.id) {
                          <div>
                            <button
                              pButton
                              label="{{ 'COMMON.LABEL.BUTTONS.OPEN_FACILITY' | translate }}"
                              icon="fa fa-fw fa-location-arrow"
                              (click)="this.openFacilityEvent.emit(f)"
                            ></button>
                          </div>
                        }
                      </div>
                    </div>
                  }
                  <!-- View for Mobile Devices -->
                  @if (isMobile()) {
                    <div class="ui-g ui-fluid">
                      <div>
                        <span class="label">{{ "LOCATION.LABEL.STATE" | translate }}:</span>
                        <span>{{ f.state }}</span>
                        <span class="label">{{ "LOCATION.LABEL.COUNTY" | translate }}:</span>
                        <span>{{ f.county }}</span>
                        <span class="label">{{ "LOCATION.LABEL.TOWNSHIP" | translate }}:</span>
                        <span>{{ f.township }}</span>
                        <span class="label">{{ "LOCATION.LABEL.AOR" | translate }}:</span>
                        <span>{{ f.aor }}</span>
                      </div>
                      <div>
                        <span class="label"
                          >{{ "LOCATION.LABEL.FACILITY_NAME" | translate }}:</span
                          >
                          <span>{{ f.facilityName }}</span>
                          <span class="label"
                            >{{ "LOCATION.LABEL.FACILITY_UID" | translate }}:</span
                            >
                            <span>{{ f.facilityUid }}</span>
                            <span class="label"
                              >{{ "LOCATION.LABEL.FACILITY_TYPE" | translate }}:</span
                              >
                              <span>{{ f.facilityType }}</span>
                            </div>
                            @if (f && f.id) {
                              <div>
                                <button
                                  pButton
                                  label="{{ 'COMMON.LABEL.BUTTONS.OPEN_FACILITY' | translate }}"
                                  icon="fa fa-fw fa-location-arrow"
                                  (click)="this.openFacilityEvent.emit(f)"
                                ></button>
                              </div>
                            }
                          </div>
                        }
                      </p-panel>
                    </div>
                  }
