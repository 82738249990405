import { Component, Input, inject, input, output } from '@angular/core';
import {
  ReactiveFormsModule,
  UntypedFormControl,
  UntypedFormGroup,
} from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonModule } from 'primeng/button';
import { DialogModule } from 'primeng/dialog';
import { PickListModule } from 'primeng/picklist';
import { take } from 'rxjs/operators';
import { CoreModule } from 'src/app/core/core.module';
import { ContactList } from 'src/app/model/contacts/contact-list';
import { MemberCode } from 'src/app/model/contacts/member-code';
import { ContactApiService } from 'src/app/services/contact-api.service';

@Component({
  selector: 'app-member-codes-form',
  imports: [
    DialogModule,
    ReactiveFormsModule,
    PickListModule,
    TranslateModule,
    ButtonModule,
    CoreModule,
  ],
  templateUrl: './member-codes-form.component.html',
  styleUrl: './member-codes-form.component.scss',
})
export class MemberCodesFormComponent {
  private _contactApi = inject(ContactApiService);

  // TODO: Skipped for migration because:
  //  Your application code writes to the input. This prevents migration.
  @Input() isVisible: boolean = false;
  readonly contactList = input<ContactList>(undefined);
  readonly formTitle = input<string>('');

  readonly memberCodesHidden = output();
  readonly memberCodesUpdated = output<MemberCode[]>();

  loading: boolean = false;
  saving: boolean = false;
  availableMemberCodes: MemberCode[] = [];
  memberCodes: MemberCode[] = [];
  sourcePicklist: MemberCode[] = [];
  targetPicklist: MemberCode[] = [];

  manageMemberCodesForm = new UntypedFormGroup({
    memberCode: new UntypedFormControl(null),
  });

  ngOnInit(): void {
    this.loadMemberCodes();
  }

  loadMemberCodes() {
    this.loading = true;
    this._contactApi
      .getMemberCodes()
      .pipe(take(1))
      .subscribe(
        ({ data }) => {
          this.availableMemberCodes = data.getMemberCodes;
          if (!this.contactList()) {
            this.sourcePicklist = [...this.availableMemberCodes];
            this.loading = false;
            return;
          }

          this.availableMemberCodes.forEach((c) => {
            if (
              this.contactList()
                .memberCodes.map((mc) => mc.id)
                .includes(c.id)
            ) {
              this.targetPicklist.push(c);
            } else {
              this.sourcePicklist.push(c);
            }
          });
          this.loading = false;
        },
        (error) => {
          console.error(error);
          this.loading = false;
        }
      );
  }

  updateContactListMemberCodes() {
    this.memberCodesUpdated.emit([...this.targetPicklist]);
  }

  onDialogHide() {
    this.memberCodesHidden.emit();
  }
}
