@if (member?.contact) {
  <div
    [class.mgr-select-class]="selected() === true"
    id="main-div"
    >
    <div [className]="cardClassName()">
      <div
        class="no-padding-bottom contact-header"
        style="border-bottom: 1px solid black"
        >
        <div class="contact-name-label small-padding">
          @if (index) {
            <span>{{ index }}.</span>
          }
          {{ member.contact.firstName }} {{ member.contact.lastName }}
          @if (member.contact.isUnavailable === 'TRUE') {
            <span>{{
              "CONTACT.LABEL.UNAVAILABLE" | translate
            }}</span>
          }
        </div>
        <div class="contact-overflow small-padding">
          {{ member.contact.jobRoleItem?.label }}
        </div>
        <div class="contact-overflow small-padding">
          {{ member.contact.companyAbbreviation }}
        </div>
        <div class="small-padding">
          <i class="fa fa-fw fa-id-card fa-2x" (click)="openProfile()"></i>
        </div>
      </div>
      @if (
        member.contact.isUnavailable === 'TRUE' &&
        member.contact.whenUnavailableLevel
        ) {
        <div
          >
          <span
            >When Unavailable Contact:
            {{ member.contact.whenUnavailableLevel }}</span
            >
            <div class="small-padding">
              <i
                class="fa fa-fw fa-id-card fa-2x"
                (click)="openProfile(member.contact.whenUnavailableId)"
              ></i>
            </div>
          </div>
        }
        <div class="contact-body">
          <div class="no-padding-top-bottom body-row">
            @if (member.contact.businessPhone) {
              <div class="no-padding">
                <span class="label no-padding"
                  >{{ "CONTACT.LABEL.OFFICE" | translate }}:</span
                  >
                  {{ member.contact.businessPhone }}
                  @if (
                    this.member.contact.afterHoursContactMethod != null &&
                    (this.member.contact.afterHoursContactMethod.value
                    ? 'OFFICE' === this.member.contact.afterHoursContactMethod.value
                    : false)
                    ) {
                    <span
                      >
                      <i class="fa fa-fw fa-check-circle" style="color: green"></i>
                      {{ "CONTACT.LABEL.PREFERRED" | translate }}
                    </span>
                  }
                </div>
              }
              @if (member.contact.home) {
                <div class="no-padding">
                  <span class="label no-padding"
                    >{{ "CONTACT.LABEL.HOME" | translate }}:</span
                    >
                    {{ member.contact.home }}
                    @if (
                      this.member.contact.afterHoursContactMethod != null &&
                      (this.member.contact.afterHoursContactMethod.value
                      ? 'HOME' === this.member.contact.afterHoursContactMethod.value
                      : false)
                      ) {
                      <span
                        >
                        <i class="fa fa-fw fa-check-circle" style="color: green"></i>
                        {{ "CONTACT.LABEL.PREFERRED" | translate }}
                      </span>
                    }
                  </div>
                }
                @if (member.contact.other) {
                  <div class="no-padding">
                    <span class="label no-padding"
                      >{{ "CONTACT.LABEL.OTHER" | translate }}:</span
                      >
                      {{ member.contact.other }}
                      @if (member.contact.note) {
                        <span>- {{ member.contact.note }}</span>
                      }
                      @if (
                        this.member.contact.afterHoursContactMethod != null &&
                        (this.member.contact.afterHoursContactMethod.value
                        ? 'PAGER' === this.member.contact.afterHoursContactMethod.value
                        : false)
                        ) {
                        <span
                          >
                          <i class="fa fa-fw fa-check-circle" style="color: green"></i>
                          {{ "CONTACT.LABEL.PREFERRED" | translate }}
                        </span>
                      }
                    </div>
                  }
                  @if (member.contact.businessMobile) {
                    <div class="no-padding">
                      <span class="label no-padding"
                        >{{ "CONTACT.LABEL.CELLULAR" | translate }}:</span
                        >
                        {{ member.contact.businessMobile }}
                        @if (
                          this.member.contact.afterHoursContactMethod != null &&
                          (this.member.contact.afterHoursContactMethod.value
                          ? 'CELLULAR' ===
                          this.member.contact.afterHoursContactMethod.value
                          : false)
                          ) {
                          <span
                            >
                            <i class="fa fa-fw fa-check-circle" style="color: green"></i>
                            {{ "CONTACT.LABEL.PREFERRED" | translate }}
                          </span>
                        }
                      </div>
                    }
                    @if (member.contact.speedDial) {
                      <div class="no-padding">
                        <span class="label no-padding"
                          >{{ "CONTACT.LABEL.SPEED_DIAL" | translate }}:</span
                          >
                          {{ member.contact.speedDial }}
                        </div>
                      }
                      @if (member.contact.personalMobile) {
                        <div class="no-padding">
                          <span class="label no-padding"
                            >{{ "CONTACT.LABEL.PERSONAL_MOBILE" | translate }}:</span
                            >
                            {{ member.contact.personalMobile }}
                            @if (
                              this.member.contact.afterHoursContactMethod != null &&
                              (this.member.contact.afterHoursContactMethod.value
                              ? 'PERSONAL CELL' ===
                              this.member.contact.afterHoursContactMethod.value
                              : false)
                              ) {
                              <span
                                >
                                <i class="fa fa-fw fa-check-circle" style="color: green"></i>
                                {{ "CONTACT.LABEL.PREFERRED" | translate }}
                              </span>
                            }
                          </div>
                        }
                        @if (member.contact.adAccountName) {
                          <div class="no-padding">
                            <span class="label no-padding"
                              >{{ "CONTACT.LABEL.EMAIL" | translate }}:</span
                              >
                              {{ member.contact.adAccountName | lowercase }}
                            </div>
                          }
                        </div>
                        <div class="body-row">
                          @if (member.contact.supervisor) {
                            <div class="no-padding">
                              <span class="label no-padding"
                                >{{ "CONTACT.LABEL.SUPERVISOR" | translate }}:</span
                                >
                                {{ member.contact.supervisor }}
                                <i
                                  class="fa fa-fw fa-id-card fa-2x"
                                  (click)="openProfile(member.contact.reportsToId)"
                                ></i>
                              </div>
                            }
                            @if (member.contact.company) {
                              <div class="no-padding">
                                <span class="label no-padding"
                                  >{{ "CONTACT.LABEL.COMPANY" | translate }}:</span
                                  >
                                  {{ member.contact.company }}
                                </div>
                              }
                            </div>
                          </div>
                        </div>
                      </div>
                    }
