<p-blockUI [blocked]="loading === true"></p-blockUI>
@if (!embedded() && ifDeviceMatches([DeviceSize.LG, DeviceSize.XL])) {
<div class="top-section">
  <app-business-unit-filter
    (selectedBusinessUnitsChanged)="handleBusinessUnitChange($event)"
    class="margin-right"
    #buFilter
  ></app-business-unit-filter>
  @if (canCreate()) {
  <button
    pButton
    label="{{ 'COMMON.LABEL.BUTTONS.NEW' | translate }}"
    icon="fa fa-fw fa-plus"
    class="margin-right"
    (click)="newContactList()"
  ></button>
  }
  <button
    pButton
    class="width-eighty margin-right"
    label="{{ 'COMMON.LABEL.BUTTONS.CLEAR' | translate }}"
    icon="fa fa-fw fa-eraser"
    (click)="clearScreen()"
  ></button>
  <button
    pButton
    class="refresh-button width-twenty"
    icon="fa fa-fw fa-refresh"
    (click)="refresh()"
  ></button>
</div>
} @if (ifDeviceMatches([DeviceSize.XS, DeviceSize.SM])) {
<p-panel
  [showHeader]="true"
  header="Filters"
  [toggleable]="true"
  [(collapsed)]="mobileFilterCollapsed"
>
  <div>
    <div>
      <app-contact-list-mobile-filter
        (mobileFilterEvent)="mobileFilter($event)"
        [showMobileFilter]="true"
      >
      </app-contact-list-mobile-filter>
    </div>
  </div>
</p-panel>
}

<app-progress-spinner [enabled]="!elements"></app-progress-spinner>
<div [style.display]="!elements ? 'none' : ''">
  <app-general-grid
    [values]="elements"
    [columns]="columns"
    [loading]="loading"
    [deviceSize]="getDeviceSize()"
    [rows]="rows"
    [totalRecords]="totalRecords"
    [filterable]="true"
    [screenName]="screenName"
    [lazy]="true"
    (lazyLoad)="lazyLoad($event)"
    [includeExportCSV]="false"
    (changeViewEvent)="changeViewEvent($event)"
    [scrollHeight]="scrollHeight"
    [usePaginator]="true"
    [rowsPerPageOptions]="[50, 100, 200]"
    #grid
  >
  </app-general-grid>
</div>
