import {
  ChangeDetectorRef,
  Component,
  Input,
  SimpleChanges,
  ViewChild,
  inject,
  input,
  output,
} from '@angular/core';
import {
  FormGroup,
  UntypedFormControl,
  UntypedFormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { Schedule } from 'src/app/model/contacts/contact-list-scheduler';
import { emptySchedule } from 'src/app/utils/utils';
import { ContactList } from 'src/app/model/contacts/contact-list';
import { Contact } from 'src/app/model/contacts/contact';
import { ContactListApiService } from 'src/app/services/contact-list-api.service';
import { ConfirmationService, MessageService } from 'primeng/api';
import { findIndex, take } from 'rxjs/operators';
import { LogAndMessageService } from 'src/app/services/log-and-message.service';
import { isNullOrEmpty } from 'src/app/core/functions/common-functions';
import moment from 'moment';
import { timezones } from 'src/app/constants/timezones';

@Component({
  selector: 'app-schedule-form',
  templateUrl: './schedule-form.component.html',
  styleUrls: ['./schedule-form.component.scss'],
  standalone: false,
})
export class ScheduleFormComponent {
  private _contactListAPI = inject(ContactListApiService);
  private _confirmationService = inject(ConfirmationService);
  private _logAndMessage = inject(LogAndMessageService);
  private _cdRef = inject(ChangeDetectorRef);

  // TODO: Skipped for migration because:
  //  Your application code writes to the input. This prevents migration.
  @Input() isVisible: boolean = false;
  readonly scheduleToEdit = input<Schedule>(emptySchedule);
  // TODO: Skipped for migration because:
  //  Your application code writes to the input. This prevents migration.
  @Input() formType: string = 'Create';
  // TODO: Skipped for migration because:
  //  Your application code writes to the input. This prevents migration.
  @Input() isLoading: boolean = false;
  readonly minDateTime = input.required<Date>();
  readonly contactList = input<ContactList>(undefined);
  // TODO: Skipped for migration because:
  //  Your application code writes to the input. This prevents migration.
  @Input() disableDelete: boolean = false;
  // TODO: Skipped for migration because:
  //  Your application code writes to the input. This prevents migration.
  @Input() contacts: Contact[] = [];

  readonly scheduleFormHidden = output();
  readonly scheduleFormSubmitted = output<any>();
  readonly scheduleDeleted = output<string>();

  timeZones: { label: string; value: string }[];

  selectedPrimary: string;
  selectedSecondary: string;

  id: string = '';
  contactListSchedules: Schedule[] = [];
  viewLoading: boolean = false;
  saving = false;

  scheduleFormGroup = new UntypedFormGroup(
    {
      startTime: new UntypedFormControl(new Date(), Validators.required),
      endTime: new UntypedFormControl(new Date(), Validators.required),
      scheduleTimeZone: new UntypedFormControl('', Validators.required),
      primary: new UntypedFormControl(null),
      secondary: new UntypedFormControl(null),
    },
    { validators: [this.contactValidator(), this.dateValidator()] }
  );

  contactValidator(): ValidatorFn {
    return (scheduleFormGroup: UntypedFormGroup): ValidationErrors | null => {
      const primary = scheduleFormGroup.get('primary').value;
      const secondary = scheduleFormGroup.get('secondary').value;

      if (
        primary === secondary &&
        !isNullOrEmpty(primary) &&
        !isNullOrEmpty(secondary)
      ) {
        return { match: true };
      }

      const error =
        isNullOrEmpty(primary) && isNullOrEmpty(secondary)
          ? { required: true }
          : null;
      return error;
    };
  }

  dateValidator(): ValidatorFn {
    return (scheduleFormGroup: UntypedFormGroup): ValidationErrors | null => {
      const startTime = scheduleFormGroup.get('startTime').value;
      const endTime = scheduleFormGroup.get('endTime').value;
      const today = new Date();

      if (startTime < today && this.formType != 'Edit') {
        return { start: true };
      }

      if (endTime <= startTime) {
        return { end: true };
      }
    };
  }

  ngOnInit(): void {
    this.prepareScheduleForm();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.contactList) {
      this.loadContacts();
    }
  }

  loadTimeZones() {
    this.timeZones = [...timezones];

    this.scheduleFormGroup.patchValue({
      scheduleTimeZone: this.timeZones.find((defaultZone) =>
        defaultZone.value?.includes('New_York')
      ),
    });
  }

  loadContacts() {
    this.contacts = this.contactList().members.map((member) => member.contact);
  }

  prepareScheduleForm() {
    this.loadTimeZones();
    this.loadContacts();
  }

  onDialogHide($event: any) {
    this.scheduleFormHidden.emit();
    // clear out the form data, so we don't have it open back up with a note partially filled in
    // if the user closes it and opens up a new schedule on another note
    setTimeout(() => {
      this.saving = false;
      this.scheduleFormGroup.markAsPristine();
      this.scheduleFormGroup.enable();
      this.disableDelete = false;
      this.scheduleFormGroup.patchValue({
        startTime: new Date(),
        endTime: new Date(),
        primary: null,
        secondary: null,
      });
    }, 50); // delay 50ms to make sure the dialog is closed before we clear it
  }

  onSubmit($event: any) {
    if (!this.scheduleFormGroup.valid) {
      // failsafe to prevent inspect element from making the submit button enabled
      console.log('Form was somehow submitted without being valid!');
      return;
    }

    if (this.formType === 'Create') {
      this.addContactListSchedule();
    } else if (this.formType === 'Edit') {
      this.editContactListSchedule($event);
    }
  }

  onTimeZoneChange($event: any) {
    this.scheduleFormGroup.patchValue({
      scheduleTimeZone: $event.value,
    });
  }

  onPrimaryContactChange($event: any) {
    this.selectedPrimary = $event.value;
    this.scheduleFormGroup.patchValue({
      primary: this.selectedPrimary,
    });
  }

  onSecondaryContactChange($event: any) {
    this.selectedSecondary = $event.value;
    this.scheduleFormGroup.patchValue({
      secondary: this.selectedSecondary,
    });
  }

  addContactListSchedule() {
    this.saving = true;
    let addSubmittedSchedule = this.scheduleFormGroup.value;
    const startTimeMoment = moment(addSubmittedSchedule.startTime);
    const convertedStart = moment.tz(
      startTimeMoment.format('YYYY-MM-DDTHH:mm:ss'),
      addSubmittedSchedule.scheduleTimeZone.value
    );
    const endTimeMoment = moment(addSubmittedSchedule.endTime);
    const convertedEnd = moment.tz(
      endTimeMoment.format('YYYY-MM-DDTHH:mm:ss'),
      addSubmittedSchedule.scheduleTimeZone.value
    );
    if (addSubmittedSchedule.primary) {
      let newSchedule = {
        id: '',
        contactId: addSubmittedSchedule.primary.id,
        contactListId: this.contactList().id,
        startTime: convertedStart.unix(),
        endTime: convertedEnd.unix(),
        timezone: addSubmittedSchedule.scheduleTimeZone.value,
        isPrimary: true,
      };

      let eventObj = {
        ...newSchedule,
        actualStartTime: addSubmittedSchedule.startTime,
        actualEndTime: addSubmittedSchedule.endTime,
        contact: {
          firstName: addSubmittedSchedule.primary.firstName,
          lastName: addSubmittedSchedule.primary.lastName,
          id: addSubmittedSchedule.primary.id,
        },
      };
      this._contactListAPI
        .addContactListSchedule(newSchedule)
        .pipe(take(1))
        .subscribe(
          ({ data }) => {
            if (data.addContactListSchedule.id) {
              this.isVisible = false;
              let createdSchedule = data.addContactListSchedule;
              this.isLoading = false;
              this.scheduleFormSubmitted.emit({
                ...eventObj,
                id: createdSchedule.id,
              });
            } else {
              console.warn('Succeeded without getting an id back?');
              this.isVisible = false;
              this.isLoading = false;
            }
          },
          (error) => {
            console.error(error);
            this.isLoading = false;
            this.saving = false;
          }
        );
    }

    if (addSubmittedSchedule.secondary) {
      let newSchedule = {
        id: '',
        contactId: addSubmittedSchedule.secondary.id,
        contactListId: this.contactList().id,
        startTime: convertedStart.unix(),
        endTime: convertedEnd.unix(),
        timezone: addSubmittedSchedule.scheduleTimeZone.value,
        isPrimary: false,
      };
      let eventObj = {
        ...newSchedule,
        actualStartTime: addSubmittedSchedule.startTime,
        actualEndTime: addSubmittedSchedule.endTime,
        contact: {
          firstName: addSubmittedSchedule.secondary.firstName,
          lastName: addSubmittedSchedule.secondary.lastName,
          id: addSubmittedSchedule.secondary.id,
        },
      };
      this._contactListAPI
        .addContactListSchedule(newSchedule)
        .pipe(take(1))
        .subscribe(
          ({ data }) => {
            if (data.addContactListSchedule.id) {
              this.isVisible = false;
              let createdSchedule = data.addContactListSchedule;
              this.scheduleFormSubmitted.emit({
                ...eventObj,
                id: createdSchedule.id,
              });
              this.isLoading = false;
            } else {
              console.warn('Succeeded without getting an id back?');
              this.isVisible = false;
              this.isLoading = false;
            }
          },
          (error) => {
            console.error(error);
            this.isLoading = false;
            this.saving = false;
          }
        );
    }
  }

  deleteContactListSchedule($event: any) {
    const today = new Date();
    let deletingSchedule = this.scheduleFormGroup.value;
    const endDate = deletingSchedule.endTime;
    if (endDate >= today) {
      this.id = this.scheduleToEdit().id;
      this._confirmationService.confirm({
        message: 'Are you sure you want to delete this schedule?',
        accept: () => {
          this.saving = true;
          this._contactListAPI
            .deleteContactListSchedule(this.id ?? '')
            .pipe(take(1))
            .subscribe(
              ({ data }) => {
                this.isVisible = false;
                this.scheduleDeleted.emit(this.id);
                setTimeout(() => {
                  this.viewLoading = false;
                }, 50);
              },
              (error) => {
                console.error(error);
                this.viewLoading = false;
                this.saving = false;
              }
            );
        },
      });
    } else {
      this.scheduleFormHidden.emit();
      this._logAndMessage.translateToErrorMessage({
        headerKey: 'CONTACT.MESSAGES.HEADERS.DELETE_SCHEDULE',
        bodyKey: 'CONTACT.MESSAGES.WARN.DELETE_PAST_SCHEDULE',
      });
    }
  }

  editContactListSchedule($event: any) {
    this.saving = true;
    const today = new Date();
    let editingSchedule = this.scheduleFormGroup.value;
    const startTime = this.scheduleFormGroup.get('startTime').value;
    const startTimeValueMoment = moment(startTime);
    const convertedStartTimeValueMoment = moment.tz(
      startTimeValueMoment.format('YYYY-MM-DDTHH:mm:ss'),
      editingSchedule.scheduleTimeZone.value
    );
    const startTimeMoment = moment(editingSchedule.startTime);
    const convertedStart = moment.tz(
      startTimeMoment.format('YYYY-MM-DDTHH:mm:ss'),
      editingSchedule.scheduleTimeZone.value
    );
    const endTimeMoment = moment(editingSchedule.endTime);
    const convertedEnd = moment.tz(
      endTimeMoment.format('YYYY-MM-DDTHH:mm:ss'),
      editingSchedule.scheduleTimeZone.value
    );

    let newSchedule = {
      id: this.scheduleToEdit().id,
      contactId: editingSchedule.primary
        ? editingSchedule.primary.id
        : editingSchedule.secondary.id,
      contactListId: this.contactList().id,
      startTime: convertedStartTimeValueMoment.unix(),
      endTime: convertedEnd.unix(),
      timezone: editingSchedule.scheduleTimeZone.value,
      isPrimary: editingSchedule.primary ? true : false,
      version: editingSchedule.version,
    };

    if (startTime > today) {
      newSchedule.startTime = convertedStart.unix();
    }

    let contactInformation = {
      contact: {
        firstName: '',
        lastName: '',
        id: '',
      },
    };

    if (editingSchedule.primary) {
      contactInformation = {
        contact: {
          firstName: editingSchedule.primary.firstName,
          lastName: editingSchedule.primary.lastName,
          id: editingSchedule.primary.id,
        },
      };
    } else if (editingSchedule.secondary) {
      contactInformation = {
        contact: {
          firstName: editingSchedule.secondary.firstName,
          lastName: editingSchedule.secondary.lastName,
          id: editingSchedule.secondary.id,
        },
      };
    }

    let eventObj = {
      ...newSchedule,
      actualStartTime: editingSchedule.startTime,
      actualEndTime: editingSchedule.endTime,
      ...contactInformation,
    };

    this._contactListAPI
      .editContactListSchedule(newSchedule)
      .pipe(take(1))
      .subscribe(
        ({ data }) => {
          if (data.editContactListSchedule.id) {
            this.isVisible = false;
            let editedSchedule = data.editContactListSchedule;
            let oldIndex = this.contactListSchedules!.findIndex(
              (x) => x.id === newSchedule.id
            );
            this.contactListSchedules?.splice(oldIndex, 1, editedSchedule);
            this.scheduleFormSubmitted.emit(eventObj);
            this.isLoading = false;
          } else {
            console.warn('Somehow succeeded without getting an id back?');
            this.isVisible = false;
            this.isLoading = false;
          }
        },
        (error) => {
          console.error(error);
          this.isLoading = false;
          this.saving = false;
        }
      );
  }
}
