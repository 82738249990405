<app-progress-spinner [enabled]="!contactList"></app-progress-spinner>
<p-blockUI [blocked]="saving === true || loading === true"></p-blockUI>
@if (contactList) {
  <div
    id="facility-create-form"
    class="contact-list-create-form"
    >
    <form [formGroup]="form" id="contact-create-form">
      <div id="facility-action-buttons">
        @if (canCreate === true || canEdit === true || canDelete === true) {
          <div
            class="no-padding-top-bottom-top-bottom buttons"
            >
            <div class="button-wrapper" style="display: flex; align-items: center">
              <div>
                <app-progress-spinner [enabled]="saving"></app-progress-spinner>
              </div>
              @if (showActionsDropdown()) {
                <div
                  class="no-padding-top-bottom"
                  style="text-align: left !important"
                  >
                  <p-dropdown
                    #dd
                    [options]="actions"
                    [(ngModel)]="selectedAction"
                    [ngModelOptions]="{ standalone: true }"
                    (onChange)="actionEvent($event, dd)"
                    [(ngModel)]="selectedAction"
                    [ngModelOptions]="{ standalone: true }"
                    styleClass="action-drop-down"
                  ></p-dropdown>
                </div>
              }
              @if (canDelete === true && this.contactList.id) {
                <div
                  class="button-wrapper no-padding-top-bottom"
                  >
                  <button
                    pButton
                    label="{{ 'COMMON.LABEL.BUTTONS.DELETE' | translate }}"
                    icon="fa fa-fw fa-trash"
                    (click)="deleteExternalList()"
                  ></button>
                </div>
              }
              @if (isEditting === false && canEdit === true) {
                <div
                  class="button-wrapper no-padding-top-bottom"
                  >
                  <button
                    pButton
                    label="{{ 'COMMON.LABEL.BUTTONS.EDIT' | translate }}"
                    icon="fa fa-fw fa-edit"
                    (click)="setEditting()"
                  ></button>
                </div>
              }
              @if (
                isEditting === true && (canEdit === true || canCreate === true)
                ) {
                <div
                  class="button-wrapper no-padding-top-bottom"
                  >
                  <button
                    pButton
                    label="{{ 'COMMON.LABEL.BUTTONS.SAVE' | translate }}"
                    icon="fa fa-fw fa-save"
                    [disabled]="!this.form.valid || this.saving"
                    (click)="saveExternalContactList()"
                  ></button>
                </div>
              }
              @if (
                this.contactList &&
                this.contactList.id &&
                (canCreate === true || canEdit === true)
                ) {
                <div
                  class="button-wrapper no-padding-top-bottom"
                  >
                  <button
                    pButton
                    label="{{ 'COMMON.LABEL.BUTTONS.ADD' | translate }}"
                    icon="fa fa-fw fa-plus"
                    (click)="addMember()"
                  ></button>
                </div>
              }
            </div>
          </div>
        }
        <div class="content">
          <div class="row">
            <span class="label text-right"
              >{{ "CONTACT.LABEL.NAME" | translate }}:</span
              >
              <span class="input">
                <input pInputText formControlName="name" />
              </span>
            </div>
            <div class="row">
              <span class="label text-right"
                >{{ "CONTACT.LABEL.NOTES" | translate }}:</span
                >
                <span class="input">
                  <input pInputText formControlName="notes" />
                </span>
              </div>
              <div class="row">
                <span class="label"
                  >{{ "COMMON.LABEL.BUSINESS_UNITS" | translate }}:<span
                  class="required"
                  >*</span
                  ></span
                  >
                  <span class="row-input">
                    <p-multiSelect
                      [options]="availableBusinessUnits"
                      formControlName="businessUnits"
                      size="4"
                      [style]="{ width: 'inherit' }"
                      >
                    </p-multiSelect>
                  </span>
                </div>
                @if (members) {
                  <div>
                    <app-general-grid
                      [values]="members"
                      [columns]="memberColumns"
                      [loading]="loading"
                      [deviceSize]="getDeviceSize()"
                      [filterable]="false"
                      [screenName]="screenName"
                      [lazy]="true"
                      [includeExportCSV]="false"
                      (changeViewEvent)="modifyMember($event)"
                      [usePaginator]="false"
                      [rowsPerPageOptions]="[50, 100, 200]"
                      #grid
                      >
                    </app-general-grid>
                  </div>
                }
              </div>
            </div>
          </form>
        </div>
      }

      <p-dialog
        header="External Contact Details"
        [(visible)]="displayDialog"
        showEffect="fade"
        [modal]="true"
        [transitionOptions]="'0ms'"
        (onHide)="resetEdittingMember()"
        [style]="{ width: '500px' }"
        >
        <form [formGroup]="externalContactCreateForm" class="external-form">
          <div class="form-body">
            <div class="form-header-buttons">
              @if ((canCreate || canEdit) && isEdittingMember === false) {
                <div
                  class="button"
                  >
                  <button
                    pButton
                    label="{{ 'COMMON.LABEL.BUTTONS.EDIT' | translate }}"
                    icon="fa fa-fw fa-edit"
                    (click)="editMember()"
                  ></button>
                </div>
              }
              @if ((canCreate || canEdit) && isEdittingMember === true) {
                <div
                  class="button"
                  >
                  <button
                    pButton
                    label="{{ 'COMMON.LABEL.BUTTONS.SAVE' | translate }}"
                    icon="fa fa-fw fa-save"
                    (click)="updateMember()"
                    [disabled]="!this.externalContactCreateForm.valid"
                  ></button>
                </div>
              }
              @if (canEdit) {
                <div class="button">
                  <button
                    pButton
                    label="{{ 'COMMON.LABEL.BUTTONS.DELETE' | translate }}"
                    icon="fa fa-fw fa-trash"
                    (click)="deleteMember()"
                  ></button>
                </div>
              }
            </div>

            <div class="form-inputs">
              <div class="row">
                <span class="label">{{ "CONTACT.LABEL.NAME" | translate }}:</span>
                <span class="input">
                  <input pInputText formControlName="name" />
                </span>
              </div>
              <div class="row">
                <span class="label"
                  >{{ "CONTACT.LABEL.DESCRIPTION" | translate }}:</span
                  >
                  <span class="input">
                    <input pInputText formControlName="description" />
                  </span>
                </div>
                <div class="row">
                  <span class="label">{{ "CONTACT.LABEL.PHONE" | translate }}:</span>
                  <span class="input">
                    <p-inputMask
                      mask="999-999-9999"
                      formControlName="phone"
                      (onBlur)="fixMask('phone')"
                    ></p-inputMask>
                  </span>
                </div>
              </div>
            </div>
          </form>
        </p-dialog>

        <app-audit-dialog-container
          [displayDialog]="displayAuditDialog"
          [auditHistory]="history"
          (dialogHiddenEvent)="auditDialogHidden()"
          [loading]="auditLoading"
        ></app-audit-dialog-container>
